import get from 'lodash-es/get';
import filter from 'lodash-es/filter';
import forEach from 'lodash-es/forEach';
import memoize from 'proxy-memoize';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import { serializeQueryParams, getUserIds } from '../../utils';
import * as app from '../app';

/**
 * @returns {Array} list of account. e.g. [{ id, name, ownerProfilePic, ownerName, ownerEmail, plan, createdAt, expireAt, lastAccessedAt, activeUsersCount, pendingInvoicesCount, quota, fileStore }, ...]
 */
export const list = memoize((state) => {
  const _result = get(state, `account-list.result`);
  if (!_result) {
    return;
  }

  // When no records found.
  if (_result && !_result.length) {
    return _result;
  }

  return filter(_result, (account) => {
    const doc = firestoreRedux.selectors.doc(state, 'accounts', account.id);
    return !doc || !doc.deleted;
  });

  return;
});

/**
 * @param {Object} state Redux state.
 * @returns {Number} Last request page number.
 */
export const lastRequestPage = (state) => get(state, `account-list.lastRequest.page`);

/**
 *
 * @param { Object } state Redux state
 * @returns { String } status of the last request.
 */
export const lastRequestStatus = (state) => get(state, `account-list.lastRequest.status`);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when request is in-progress.
 */
export const requestInProgress = (state) => {
  const _lastRequestStatus = lastRequestStatus(state);
  return _lastRequestStatus === 'IN_PROGRESS';
};

/**
 * @override
 * @returns {Number} Records per page to be loaded.
 */
export const recordsPerPage = app.selectors.recordsPerPage;

/**
 * @returns { Boolean } `true` when all the records are loaded.
 */
export const eol = memoize((state) => {
  const _list = list(state) || [];
  const _rpp = recordsPerPage(state);
  const _lastRequestPage = lastRequestPage(state);
  const _lastRequestStatus = lastRequestStatus(state);
  return _lastRequestStatus === 'SUCCESS' && _rpp * _lastRequestPage > _list.length;
});

/**
 * @returns {Object} Default filters.
 */
export const defaultFilters = () => {
  return {
    ownerName: '',
    ownerEmail: '',
    accountIds: '',
    name: '',
    createdFrom: null,
    createdTo: null,
    lastAccessedFrom: null,
    lastAccessedTo: null,
    subscriptionExpireFrom: null,
    subscriptionExpireTo: null,
    plan: null,
    fileStore: null,
    autoRenew: false,
    pendingInvoices: false,
  };
};

/**
 * @returns { Object } Current applied filters. When no filters are applied, default filters.
 */
export const filters = memoize((state) => {
  const params = get(state, `router.page.params`);
  const urlFilters = {};
  forEach(params, (value, key) => {
    if(key in defaultFilters() && value){
      if(key === 'plan' || key === 'fileStore'){
        urlFilters[key] = value.split(',');
      } else{
        urlFilters[key] = value;
      }
    }
  });

  return {...defaultFilters(), ...urlFilters, ...get(state, `account-list.filters`, {})};
});

/**
 * @param {Object} state Redux state.
 * @returns {String} Sort By field name.
 */
 export const sortBy = (state) => {
  const _layout = app.selectors.layout(state);
  const defaultSortBy = _layout !== 'DESKTOP' ? 'EXPIRES_AT': 'CREATED_AT';
  return get(state, `account-list.sortBy`) || get(state, `router.page.params.sortBy`) || defaultSortBy;
};

/**
 * @param {Object} state Redux state.
 * @returns {String} Sort order of the list.
 */
export const sortOrder = (state) => {
  return get(state, `account-list.sortOrder`) || get(state, `router.page.params.sortOrder`) || 'DESC';
};

/**
 * @returns {String} excel sheet link with filter criteria.
 */
 export const exportAsExcelLink = memoize((state) => {
  const config = app.selectors.config(state);
  const {apiBaseUrl} = config;
  const _filters = filters(state);
  const _pageSize = recordsPerPage(state);
  const _pageNo = lastRequestPage(state) || 1;
  const _sortBy = sortBy(state);
  const _sortOrder = sortOrder(state);
  let params = { pageNo: _pageNo, pageSize: _pageSize, sortBy: _sortBy, sortOrder: _sortOrder };
  forEach(_filters, (value, key) => {
    if (value) {
      if(key === 'accountIds') {
        value = getUserIds(value);
      }
      if (Array.isArray(value)) {
        params[key] = value.join(',');
      } else {
        params[key] = value;
      }
    }
  });

  const queryString = serializeQueryParams(params);
  return `${apiBaseUrl}/account/accounts/export?${queryString}`;
});

