import { css } from '@dreamworld/pwa-helpers/lit.js';
import { DwListItem } from '@dreamworld/dw-list-item/dw-list-item';
 export class KerikaListItem extends DwListItem {

    static styles = [
        super.styles,
        css`
        :host {
            --subtitle1-font-size: var(--body2-font-size);
            --subtitle1-line-height: var(--body2-line-height);
            --subtitle1-font-weight: var(--body2-font-weight);
            --subtitle1-letter-spacing: var(--body2-letter-spacing);
            --subtitle1-text-transform: none;
        }
        
        :host(:not([disabled]))::before {
            background-color: var(--mdc-theme-primary, #6200ee);
        }
    `]
    
    constructor() {
        super();
        this.showSelectedRipple = true;
    }

 }
customElements.define('kerika-list-item', KerikaListItem);
