import get from 'lodash-es/get';

/**
 * @param {Object} state Redux state.
 * @returns {String} Current page name.
 */
export const pageName = (state) => get(state, `router.page.name`);

/**
 * @param {Object} state Redux state
 * @returns {String} Current dialog name.
 */
export const dialongName = (state) => get(state, `router.dialog.name`);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when `?action=filters` query param set in url.
 */
export const actionName = (state) => {
  return get(state, `router.page.params.action`, '');
};

/**
 * @param {Object} state  Redux state
 * @returns {Object} params of current page.
 */
export const pageParams = (state) => get(state, `router.page.params`, {});

/**
 * @param {Object} state  Redux state
 * @returns {Object} params of current dialog.
 */
export const dialogParams = (state) => get(state, `router.dialog.params`, {});

/**
 * @param {Object} state Redux state
 * @returns {String} Account from url.
 */
export const accountId = (state) => get(state, `router.dialog.params.accountId`, '');
