import { LitElement, html, css } from '@dreamworld/pwa-helpers/lit';
import { MdNavigationDrawer } from '@material/web/navigationdrawer/navigation-drawer';
export class KerikaNavigationDrawer extends MdNavigationDrawer {
  static styles = [
    super.styles,
    css`
      :host {
        height: 100vh;
        padding: 12px;
        box-sizing: border-box;
        --md-navigation-drawer-container-shape-start-start: 8px;
        --md-navigation-drawer-container-shape-end-start: 8px;
        --md-navigation-drawer-container-shape-end-end:8px;
        --md-navigation-drawer-container-shape-start-end:8px;
        --md-navigation-drawer-standard-container-elevation-shadow: var(--mdc-elevation--z2);
        --md-navigation-drawer-container-width: 250px;
        position: sticky;
        top: 0;
      }
    `
  ];
}
customElements.define('kerika-navigation-drawer', KerikaNavigationDrawer);
