import * as actions from './actions';

/**
 * Auth Reducer
 */
export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE_AUTH:
      return { user: action.user };
    default:
      return state;
  }
};
