import get from 'lodash-es/get';
import orderBy from 'lodash-es/orderBy';
import cloneDeep from 'lodash-es/cloneDeep';
import memoize from 'proxy-memoize';
import * as app from '../app';
import lowerCase from 'lodash-es/lowerCase';
import find from 'lodash-es/find';

/**
 * @returns {Array}
 *  @param {String} domain e.g. 'dreamworld.dev'
 *  @param {Number} accountsCount
 *  @param {Number} usersCount
 *  @param {Timestamp} addedAt
 *  @param {Object} addedBy
 */
export const domains = memoize((state) => {
  const result = get(state, `non-profit-domains.domains.task.result`);
  if (!result) {
    return;
  }
  const sort = domainsSort(state);
  return orderBy(cloneDeep(result), [sort.by], [sort.order]);
});

/**
 * @returns {Object} single domain  detail.
 */
export const domain = memoize(({state, domain}) => {
  const _list = domains(state);
  return find(_list, {domain}) || {};
})

/**
 * @param {Object} state Redux state
 * @returns {String} Current Tab in domain dialog. e.g. 'ACCOUNTS' or 'USERS'.
 */
export const currentDomainTab = memoize((state) => {
  const tab = get(state, `router.page.params.tab`) || 'accounts';
  return tab.toUpperCase();
});

/**
 * @returns {Array}
 *  @param {id} id e.g. 'acc_2jf0nlair9ifi'
 *  @param {String} name
 *  @param {String} ownerName
 *  @param {String} ownerEmail
 *  @param {String} plan
 *  @param {Number} usedSubscriptions
 */
export const accounts = memoize(({ state, domain }) => {
  const accountsUsers = get(state, `non-profit-domains.accountsUsers`, {});
  const domainDetail = accountsUsers[domain];
  const _accounts = get(domainDetail, `result.accounts`);
  if (!_accounts) {
    return;
  }

  const sort = accountsSort(state);
  return orderBy(cloneDeep(_accounts), [(account) => lowerCase(account[sort.by])], [sort.order]);
});

/**
 * @returns {Array}
 *  @param {id} id e.g. 'acc_2jf0nlair9ifi'
 *  @param {String} name
 *  @param {String} email
 *  @param {String} profileImage
 *  @param {Timestamp} signupAt
 *  @param {Timestamp} lastSeenAt
 */
export const users = memoize(({ state, domain }) => {
  const accountsUsers = get(state, `non-profit-domains.accountsUsers`, {});
  const domainDetail = accountsUsers[domain];
  const _users = get(domainDetail, `result.users`);
  if (!_users) {
    return;
  }

  const sort = usersSort(state);
  return orderBy(cloneDeep(_users), [(user) => lowerCase(user[sort.by])], [sort.order]);
});

/**
 *
 * @param {Object} state Redux state.
 * @returns {String} Status of the last domains request.
 */
export const domainsTaskStatus = (state) => get(state, `non-profit-domains.domains.task.status`);

/**
 *
 * @param {Object} state Redux state.
 * @param {String} domanName Domain name.
 * @returns {String} current status of the accountsUsers request.
 */
export const accountsUsersTaskStatus = (state, domainName) => {
  const _accountsUsers = get(state, `non-profit-domains.accountsUsers`) || {};
  const _task = _accountsUsers[domainName] || {};
  return _task.status;
};

/**
 * @param {Object} state Redux state.
 * @returns {Object} current sort criteria. e.g { by, order }
 */
export const domainsSort = (state) => get(state, `non-profit-domains.domains.sort`, { by: 'domain', order: 'asc' });

/**
 * @param {Object} state Redux state.
 * @returns {Object} current sort criteria. e.g { by, order }
 */
export const accountsSort = (state) => get(state, `non-profit-domains.accountsUsers.sortAccounts`, { by: 'name', order: 'asc' });

/**
 * @param {Object} state Redux state.
 * @returns {Object} current sort criteria. e.g { by, order }
 */
export const usersSort = (state) => get(state, `non-profit-domains.accountsUsers.sortUsers`, { by: 'name', order: 'asc' });

/**
 * @param {Redux} state Redux state.
 * @returns {Number} Number of free subscriptions for non-profit domain.
 */
export const freeSubscriptions = (state) => get(state, `app.config.nonProfitDomainFreeSubscriptions`, 10);

/**
 * @param {Object} state Redux state.
 * @returns {String} Downloadable link.
 */
export const domainsExportLink = (state) => {
  const { apiBaseUrl } = app.selectors.config(state);
  const sort = domainsSort(state);
  const sortByMap = { domain: 'DOMAIN', accountsCount: 'ACCOUNTS', usersCount: 'USERS', addedAt: 'SINCE' };
  return `${apiBaseUrl}/subscription/non-profits.xlsx?sort-by=${sortByMap[sort.by]}&sort-order=${sort.order.toUpperCase()}`;
};

/**
 * @param {Object} state Redux state.
 * @param {String} domain Domain name.
 * @returns {String} Downloadable link.
 */
export const accountsUsersExportLink = (state, domain) => {
  const { apiBaseUrl } = app.selectors.config(state);
  const _accountsSort = accountsSort(state);
  const _usersSort = usersSort(state);
  const accountsSortByMap = { name: 'NAME', ownerName: 'OWNER_NAME', ownerEmail: 'EMAIL', plan: 'PLAN', usedSubscriptions: 'TEAM_SIZE' };
  const usersSortByMap = { name: 'NAME', email: 'EMAIL', signupTime: 'SIGNUP', lastSeenTime: 'LAST_SEEN' };
  return `${apiBaseUrl}/subscription/non-profits/${domain}/accounts-users.xlsx?accounts-sort-by=${
    accountsSortByMap[_accountsSort.by]
  }&users-sort-by=${
    usersSortByMap[_usersSort.by]
  }&accounts-sort-order=${_accountsSort.order.toUpperCase()}&users-sort-order=${_usersSort.order.toUpperCase()}`;
};
