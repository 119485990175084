import { store, sagaMiddleware } from '../store';
import saga from './saga';

import * as _actions from './actions';
import * as _reducers from './reducers';
import * as _selectors from './selectors';

export const actions = _actions;
export const reducers = _reducers;
export const selectors = _selectors;

store.addReducers({
  'non-profit-domains': _reducers.nonProfitDomains
});

sagaMiddleware.run(saga);


