const INITIAL_STATE = {};
import * as actions from './actions.js';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import without from 'lodash-es/without';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils.js';

export const nonProfitDomains = (state = INITIAL_STATE, action) => {
  let accountsUsers = state.accountsUsers || {};
  switch (action.type) {
    case actions.LOAD:
      state = ReduxUtils.replace(state, `domains.task.status`, 'PENDING');
      return ReduxUtils.replace(state, `domains.task.lastRequestAt`, Date.now());
    case actions.LOAD_DONE:
      state = ReduxUtils.replace(state, `domains.task.status`, 'COMPLETE');
      state = ReduxUtils.replace(state, `domains.task.lastResponseAt`, Date.now());
      return ReduxUtils.replace(state, `domains.task.result`, action.list);
    case actions.LOAD_FAILED:
      state = ReduxUtils.replace(state, `domains.task.status`, 'ERROR');
      return ReduxUtils.replace(state, `domains.task.error`, action.error);
    case actions.LOAD_ACCOUNTS_USERS:
      return {
        ...state,
        accountsUsers: {
          ...accountsUsers,
          [action.domain]: {
            ...accountsUsers[action.domain],
            status: 'IN_PROGRESS',
            lastRequestedAt: Date.now(),
          },
        },
      };
    case actions.LOAD_ACCOUNTS_USERS_DONE:
      return {
        ...state,
        accountsUsers: {
          ...state.accountsUsers,
          [action.domain]: {
            ...state.accountsUsers[action.domain],
            status: 'COMPLETE',
            lastResponseAt: Date.now(),
            result: action.result,
          },
        },
      };
    case actions.LOAD_ACCOUNTS_USERS_FAILED:
      return {
        ...state,
        accountsUsers: {
          ...state.accountsUsers,
          [action.domain]: {
            ...state.accountsUsers[action.domain],
            status: 'ERROR',
            lastResponseAt: Date.now(),
            error: action.error,
          },
        },
      };
    case actions.UPDATE_SORT_ORDER:
      return ReduxUtils.replace(state, `domains.sort`, { by: action.sortBy, order: action.sortOrder });
    case actions.UPDATE_ACCOUNTS_SORT_ORDER:
      return ReduxUtils.replace(state, `accountsUsers.sortAccounts`, { by: action.sortBy, order: action.sortOrder });
    case actions.UPDATE_USERS_SORT_ORDER:
      return ReduxUtils.replace(state, `accountsUsers.sortUsers`, { by: action.sortBy, order: action.sortOrder });

    case actions.ADD:
      const domains = get(state, `domains.task.result`);
      const alreadyExists = !!find(domains, { domain: action.domain });
      if (alreadyExists) {
        return state;
      }
      const domainTask = get(state, `accountsUsers`, {})[action.domain] || {};
      const accountsCount = get(domainTask, 'result.accounts', []).length;
      const usersCount = get(domainTask, 'result.users', []).length;
      const list = get(state, `domains.task.result`);
      const newDoc = { domain: action.domain, accountsCount, usersCount, addedAt: Date.now(), addedBy: action.addedBy || {} };
      return ReduxUtils.replace(state, `domains.task.result`, [...list, newDoc]);

    case actions.ADD_FAILED:
      if(action.error === 'ALRADY_EXIST'){
        return state;
      }
      const result = get(state, `domains.task.result`);
      const addedDoc = find(result, { domain: action.domain });
      return ReduxUtils.replace(state, `domains.task.result`, without(result, addedDoc));

    case actions.REMOVE:
      const _result = get(state, `domains.task.result`);
      const doc = find(_result, { domain: action.domain });
      if (!doc) {
        return state;
      }
      return ReduxUtils.replace(state, `domains.task.result`, without(_result, doc));

    case actions.REMOVE_FAILED:
      const __result = get(state, `domains.task.result`);
      return ReduxUtils.replace(state, `domains.task.result`, [...__result, action.domain]);
    default:
      return state;
  }
};
