export const LOAD_NEXT_PAGE = 'USER_LOAD_NEXT_PAGE';
export const REQUEST_LIST = 'USER_REQUEST_LIST';
export const REQUEST_LIST_SUCCEED = 'USER_REQUEST_LIST_SUCCEED';
export const REQUEST_LIST_FAILED = 'USER_REQUEST_LIST_FAILED';
export const CANCEL_LIST_REQUEST = 'USER_CANCEL_LIST_REQUEST';
export const IGNORE_LIST_REQUEST = 'USER_IGNORE_LIST_REQUEST';

export const UPDATE_FILTERS = 'USER_UPDATE_FILTERS';
export const RESET_FILTERS = 'USER_RESET_FILTERS';

export const UPDATE_SORT_ORDER = 'USER_UPDATE_SORT_ORDER';


export const DELETE = 'USER_DELETE';
export const DELETE_SUCCEED = 'USER_DELETE_SUCCEED';
export const DELETE_FAILED = 'USER_DELETE_FAILED';

export const RESTORE = 'USER_RESTORE';
export const RESTORE_SUCCEED = 'USER_RESTORE_SUCCEED';
export const RESTORE_FAILED = 'USER_RESTORE_FAILED';

export const REFRESH_LIST = 'USER_REFRESH_LIST';
export const REFRESH_LIST_SUCCEED = 'USER_REFRESH_LIST_SUCCEED';
export const REFRESH_LIST_FAILED = 'USER_REFRESH_LIST_FAILED';

export const REFRESH_COUNTRY_LIST = 'USER_REFRESH_COUNTRY_LIST';
export const REFRESH_COUNTRY_LIST_SUCCEED = 'USER_REFRESH_COUNTRY_LIST_SUCCEED';
export const REFRESH_COUNTRY_LIST_FAILED = 'USER_REFRESH_COUNTRY_LIST_FAILED';

export const REFRESH_UTM_LIST = 'USER_REFRESH_UTM_LIST';
export const REFRESH_UTM_LIST_SUCCEED = 'USER_REFRESH_UTM_LIST_SUCCEED';
export const REFRESH_UTM_LIST_FAILED = 'USER_REFRESH_UTM_LIST_FAILED';

export const UPDATE_USER_DETAILS = 'USER_UPDATE_USER_DETAILS';
export const UPDATE_USER_DETAILS_SUCCEED = 'USER_UPDATE_USER_DETAILS_SUCCEED';
export const UPDATE_USER_DETAILS_FAILED = 'USER_UPDATE_USER_DETAILS_FAILED';

export const REFRESH_JOB_ROLE_LIST = 'USER_REFRESH_JOB_ROLE_LIST';
export const REFRESH_JOB_ROLE_LIST_SUCCEED = 'USER_REFRESH_JOB_ROLE_LIST_SUCCEED';
export const REFRESH_JOB_ROLE_LIST_FAILED = 'USER_REFRESH_JOB_ROLE_LIST_FAILED';

export const REFRESH_TASKBOARD_AWARE_LIST = 'USER_REFRESH_TASKBOARD_AWARE_LIST';
export const REFRESH_TASKBOARD_AWARE_LIST_SUCCEED = 'USER_REFRESH_TASKBOARD_AWARE_LIST_SUCCEED';
export const REFRESH_TASKBOARD_AWARE_LIST_FAILED = 'USER_REFRESH_TASKBOARD_AWARE_LIST_FAILED';

//First session actions
export const REFRESH_TOTAL_DURATION_LIST = 'USER_REFRESH_TOTAL_DURATION_LIST';
export const REFRESH_TOTAL_DURATION_LIST_SUCCEED = 'USER_REFRESH_TOTAL_DURATION_LIST_SUCCEED';
export const REFRESH_TOTAL_DURATION_LIST_FAILED = 'USER_REFRESH_TOTAL_DURATION_LIST_FAILED';

export const REFRESH_ACTIVITY_DURATION_LIST = 'USER_REFRESH_ACTIVITY_DURATION_LIST';
export const REFRESH_ACTIVITY_DURATION_LIST_SUCCEED = 'USER_REFRESH_ACTIVITY_DURATION_LIST_SUCCEED';
export const REFRESH_ACTIVITY_DURATION_LIST_FAILED = 'USER_REFRESH_ACTIVITY_DURATION_LIST_FAILED';

export const REFRESH_OS_LIST = 'USER_REFRESH_OS_LIST';
export const REFRESH_OS_LIST_SUCCEED = 'USER_REFRESH_OS_LIST_SUCCEED';
export const REFRESH_OS_LIST_FAILED = 'USER_REFRESH_OS_LIST_FAILED';

export const START_IMPERSONATED_SESSIONS = 'USER_START_IMPERSONATED_SESSIONS';
export const START_IMPERSONATED_SESSIONS_FAILED = 'USER_START_IMPERSONATED_SESSIONS_FAILED';
export const START_IMPERSONATED_SESSIONS_SUCCESS = 'USER_START_IMPERSONATED_SESSIONS_SUCCESS';

/**
 * Loads next page based on lastRequest, filters & sorting criteria.
 * When previous request is in-progress, does not request to load data from server.
 */
export const loadNextPage = () => {
  return {
    type: LOAD_NEXT_PAGE
  };
};

/**
 * Requests to get users list based on  lastRequest, filters & sorting criteria.
 * Updates `lastRequest.status` to `IN_PROGRESS`
 * @param {Boolean} nextPage When it's `true` requests for next page otherwise loads 1st page records.
 */
export const _requestList = (nextPage) => {
  return {
    type: REQUEST_LIST,
    nextPage
  }
}

/**
 * Dispatched when list request succeed.
 * Updates result, lastRequest details.
 * @param {Array} data List of users
 * @param {Boolean} nextPage When `true`, appends data to current list otherwise overrides result with current data.
 * @returns
 */
export const _requestListSucceed = (data, nextPage) => {
  return {
    type: REQUEST_LIST_SUCCEED,
    data,
    nextPage
  };
};

/**
 * Dispatched when list request failed with any reason.
 * Updates `lastRequest`'s status to `FAILED`.
 * @param {Object} error Error code
 */
export const _requestListFailed = (error) => {
  return {
    type: REQUEST_LIST_FAILED,
    error,
  };
};

/**
 * Cancels the last `in-progress` request.
 * @param {String} reason Reason to cancel the request. Possible values: 'UPDATE_FILTERS', 'UPDATE_SORT_ORDER', 'PAGE_CLOSED'
 */
export const _cancelListRequest = (reason) => {
  return {
    type: CANCEL_LIST_REQUEST,
    reason
  }
}

/**
 * This action is just for notification into redux-devtools.
 * It's dispatched when previous request is already in-progress & view component requests to load next page.
 */
export const _ignoreListRequest = () => {
  return {
    type: IGNORE_LIST_REQUEST
  }
}

/**
 * Requests to refresh user list based on lastRequest & sorting criteria.
 */
export const _refreshList = () => {
  return {
    type: REFRESH_LIST,
  };
};

/**
 * Dispatched when list refresh succeed.
 * Updates result details.
 * @param {Array} data List of user
 * @returns
 */
export const _refreshListSucceed = (data) => {
  return {
    type: REFRESH_LIST_SUCCEED,
    data
  };
};

/**
 * Dispatched when list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshListFailed = (error) => {
  return {
    type: REFRESH_LIST_FAILED,
    error,
  };
};

/**
 *
 * @param {Object} filters Filter criteria. e.g. { name, email, userId, signupFrom, signupTo, lastSeenFrom, lastSeenTo, userType, authService, device, browser }
 */
export const updateFilters = (filters) => {
  return {
    type: UPDATE_FILTERS,
    filters
  }
}


/**
 * Resets to default filters.
 */
export const resetFilters = () => {
  return {
    type: RESET_FILTERS
  }
}

/**
 * Updates sort criteria.
 * @param {String} sortBy Sort By field name. e.g. 'NAME' or 'EMAIL' or 'SIGNUP' or 'LAST_SEEN'
 * @param {String} sortOrder Sort order. e.g. 'ASC' or 'DESC'
 */
export const updateSortOrder = (sortBy, sortOrder) => {
  return {
    type: UPDATE_SORT_ORDER,
    sortBy,
    sortOrder
  }
}

/**
 * Deletes single user
 * @param {String} userId User Id
 * @param {Boolean} backup When given `true`, user could be restored in future.
 */
export const deleteUser = (userId, backup=true) => {
  return {
    type: DELETE,
    userId,
    backup
  }
}

/**
 * Dispatched when delete request is succeed.
 * @param {String} userId User Id
 */
export const _deleteSucceed = (userId) => {
  return {
    type: DELETE_SUCCEED,
    userId
  }
}

/**
 * Dispatched when delete request is failed.
 * @param {String} userId User Id.
 * @param {Object} error Error detail
 */
export const _deleteFailed = (userId, error) => {
  return {
    type: DELETE_FAILED,
    userId,
    error
  }
}

/**
 * Restores multiple users.
 * @param {Array} userIds List of Use Ids.
 */
export const restore = (userIds) => {
  return {
    type: RESTORE,
    userIds
  }
}

/**
 * Restores multiple users.
 * @param {Array} userIds List of Use Ids.
 */
export const _restoreSucceed = (userIds) => {
  return {
    type: RESTORE_SUCCEED,
    userIds
  }
}

/**
 * Restores multiple users.
 * @param {Array} userIds List of Use Ids.
 * @param {Object} error Error details.
 */
export const _restoreFailed = (userIds, error) => {
  return {
    type: RESTORE_FAILED,
    userIds,
    error
  }
}

/**
 * Requests to refresh country list based on lastRequest & sorting criteria.
 */
export const refreshCountryList = () => {
  return {
    type: REFRESH_COUNTRY_LIST,
  };
};

/**
 * Dispatched when country list refresh succeed.
 * Updates result details.
 * @param {Array} data List of country
 * @returns
 */
export const _refreshCountryListSucceed = (data) => {
  return {
    type: REFRESH_COUNTRY_LIST_SUCCEED,
    data
  };
};

/**
 * Dispatched when country list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshCountryListFailed = (error) => {
  return {
    type: REFRESH_COUNTRY_LIST_FAILED,
    error,
  };
};

/**
 * Requests to refresh utm list based on lastRequest & sorting criteria.
 */
export const refreshUTMList = () => {
  return {
    type: REFRESH_UTM_LIST,
  };
};

/**
 * Dispatched when utm list refresh succeed.
 * Updates result details.
 * @param {Array} data List of utm details
 * @returns
 */
export const _refreshUTMListSucceed = (data) => {
  return {
    type: REFRESH_UTM_LIST_SUCCEED,
    data
  };
};

/**
 * Dispatched when utm list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshUTMListFailed = (error) => {
  return {
    type: REFRESH_UTM_LIST_FAILED,
    error,
  };
};

/**
 * Update user details.
 * @param {Array} userIds optional.
 *  - If this field is not passsed then preference update based on current filters.
 */
export const updateUserDetails = ({properties, preference, userIds}) => {
  return {
    type: UPDATE_USER_DETAILS,
    userIds,
    preference,
    properties
  }
}

export const _updateUserDetailsSuccess = ({properties, preference, userIds}) => {
  return {
    type: UPDATE_USER_DETAILS_SUCCEED,
    userIds,
    preference,
    properties
  }
}

export const _updateUserDetailsFailed = ({properties, preference, userIds, error}) => {
  return {
    type: UPDATE_USER_DETAILS_FAILED,
    userIds,
    preference,
    properties,
    error
  }
}

/**
 * Requests to refresh Total Duration based on lastRequest & sorting criteria.
 */
export const refreshTotalDurationList = () => {
  return {
    type: REFRESH_TOTAL_DURATION_LIST,
  };
};

/**
 * Dispatched when Total Duration list refresh succeed.
 * Updates result details.
 * @param {Array} data List of country
 * @returns
 */
export const _refreshTotalDurationListSucceed = (data) => {
  return {
    type: REFRESH_TOTAL_DURATION_LIST_SUCCEED,
    data
  };
};

/**
 * Dispatched when Total Duration list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshTotalDurationListFailed = (error) => {
  return {
    type: REFRESH_TOTAL_DURATION_LIST_FAILED,
    error,
  };
};


/**
 * Requests to refresh  Activity Duration based on lastRequest & sorting criteria.
 */
export const refreshActivityDurationList = () => {
  return {
    type: REFRESH_ACTIVITY_DURATION_LIST,
  };
};

/**
 * Dispatched when Activity Duration list refresh succeed.
 * Updates result details.
 * @param {Array} data List of country
 * @returns
 */
export const _refreshActivityDurationListSucceed = (data) => {
  return {
    type: REFRESH_ACTIVITY_DURATION_LIST_SUCCEED,
    data
  };
};

/**
 * Dispatched when Activity Duration list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshActivityDurationListFailed = (error) => {
  return {
    type: REFRESH_ACTIVITY_DURATION_LIST_FAILED,
    error,
  };
};


/**
 * Requests to refresh os based on lastRequest & sorting criteria.
 */
export const refreshOsList = () => {
  return {
    type: REFRESH_OS_LIST,
  };
};

/**
 * Dispatched when os list refresh succeed.
 * Updates result details.
 * @param {Array} data List of country
 * @returns
 */
export const _refreshOsListSucceed = (data) => {
  return {
    type: REFRESH_OS_LIST_SUCCEED,
    data
  };
};

/**
 * Dispatched when os list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshOsListFailed = (error) => {
  return {
    type: REFRESH_OS_LIST_FAILED,
    error,
  };
};


/**
 * Requests to refresh job-role based on lastRequest & sorting criteria.
 */
export const refreshJobRoleList = () => {
  return {
    type: REFRESH_JOB_ROLE_LIST,
  };
};

/**
 * Dispatched when job-role list refresh succeed.
 * Updates result details.
 * @param {Array} data List of country
 * @returns
 */
export const _refreshJobRoleListSucceed = (data) => {
  return {
    type: REFRESH_JOB_ROLE_LIST_SUCCEED,
    data
  };
};

/**
 * Dispatched when job-role list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshJobRoleListFailed = (error) => {
  return {
    type: REFRESH_JOB_ROLE_LIST_FAILED,
    error,
  };
};


/**
 * Requests to refresh taskboardAware based on lastRequest & sorting criteria.
 */
export const refreshTaskboardAwareList = () => {
  return {
    type: REFRESH_TASKBOARD_AWARE_LIST,
  };
};

/**
 * Dispatched when taskboardAware list refresh succeed.
 * Updates result details.
 * @param {Array} data List of country
 * @returns
 */
export const _refreshTaskboardAwareListSucceed = (data) => {
  return {
    type: REFRESH_TASKBOARD_AWARE_LIST_SUCCEED,
    data
  };
};

/**
 * Dispatched when taskboardAware list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshTaskboardAwareListFailed = (error) => {
  return {
    type: REFRESH_TASKBOARD_AWARE_LIST_FAILED,
    error,
  };
};

/**
 * Dispatch this action to start impersonate session.
 */
export const startImpersonatedSessions = (userId) => {
  return {
    type: START_IMPERSONATED_SESSIONS,
    userId,
  }
}

export const _startImpersonatedSessionsSuccess = (userId) => {
  return {
    type: START_IMPERSONATED_SESSIONS_SUCCESS,
    userId,
  }
}

export const _startImpersonatedSessionsFailed = (userId, error) => {
  return {
    type: START_IMPERSONATED_SESSIONS_FAILED,
    userId,
    error
  }
}