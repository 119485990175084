import { takeEvery, select, all, call, put } from 'redux-saga/effects';
import * as router from '../router';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import fetch from '../../fetch.js';
import { downloadUrl } from '../../utils';
import find from 'lodash-es/find';
import i18next from '../../localize/i18next.js';
import { show as showSnackBar } from '../../components/common/kerika-snackbar.js';

/**
 * Requests server to load non-profit domains.
 */
function* load() {
  try {
    const list = yield call(fetch, `/subscription/non-profits`);
    yield put(actions._loadDone(list));
  } catch (error) {
    yield put(actions._loadFailed(error.code));
    console.error(`Failed to load non-profit domains`, error);
  }
}

/**
 * Loads accounts & users of given domain.
 * @param {Object} param0 e.g. {domain}
 */
function* loadAccountsUsers({ domain }) {
  try {
    const result = yield call(fetch, `/subscription/non-profits/${domain}/accounts-users`, { excludeErrors:[409] });
    yield put(actions._loadAccountsUsersDone(domain, result || { accounts: [], users: [] }));
  } catch (error) {
    yield put(actions._loadAccountsUsersFailed(domain, error.code));
    if(error.code === 'PUBLIC_DOMAIN_NOT_ALLOWED') {
      showSnackBar({ message: i18next.t('toast.requestedPublicDomain',{ domain }), type: 'ERROR'});
      return;
    }
    console.error(`Failed to load non-profit domain accounts & users`, { domain, error });
  }
}

/**
 * Requests to export all accounts & users as an excel sheet.
 * @param {Object} param0
 *  @property {String} domain
 */
function* exportAccountsAndUsersAsExcel({ domain }) {
  const state = yield select();
  const link = selectors.accountsUsersExportLink(state, domain);
  downloadUrl(link);
}

/**
 * Requests to add new domain.
 * @param {String} param0 Domain name.
 */
function* add({ domain }) {
  try {
    yield call(fetch, `/subscription/non-profits/${domain}`, { method: 'POST' });
    yield put(actions._addDone(domain));
  } catch (error) {
    yield put(actions._addFailed(domain, error.code));
    console.error(`Failed to add non-profit domain`, error);
  }
}

/**
 * Requests to delete domain.
 * @param {String} param0 Domain name.
 */
function* remove({ domain }) {
  const state = yield select();
  const domains = selectors.domains(state);
  const doc = find(domains, { domain });
  try {
    yield call(fetch, `/subscription/non-profits/${domain}`, { method: 'DELETE' });
    yield put(actions._removeDone(domain));
  } catch (error) {
    yield put(actions._removeFailed(doc, error.code));
    console.error(`Failed to remove non-profit domain`, error);
  }
}

/**
 *  When current page is `NON_PROFIT_DOMAINS`, loads domains list.
 **/
let previousPage, previousDomain;
function* routeChangeHandler() {
  const state = yield select();
  const currentPage = router.selectors.pageName(state);
  if (currentPage === 'NON_PROFIT_DOMAINS' && previousPage !== 'NON_PROFIT_DOMAINS') {
    yield put(actions._load());
  }

  const action = router.selectors.actionName(state);
  const domain = router.selectors.pageParams(state).domain;
  if (currentPage === 'NON_PROFIT_DOMAINS' && action === 'view' && domain && domain !== previousDomain) {
    yield put(actions.loadAccountsUsers(domain));
  }

  previousDomain = domain;
  previousPage = currentPage;
}

function* watchRouter() {
  yield call(routeChangeHandler);
  yield takeEvery(router.dwRouter.ROUTE_CHANGED, routeChangeHandler);
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    call(watchRouter),
    yield takeEvery([actions.LOAD, actions.ADD_DONE, actions.REMOVE_DONE], load),
    yield takeEvery(actions.LOAD_ACCOUNTS_USERS, loadAccountsUsers),
    yield takeEvery(actions.EXPORT_ACCOUNTS_AND_USERS, exportAccountsAndUsersAsExcel),
    yield takeEvery(actions.ADD, add),
    yield takeEvery(actions.REMOVE, remove),
  ]);
}

export default saga;
