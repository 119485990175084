import * as router from '../../redux/router';

/**
 * Provides a way to manage history for dialog.
 *
 * ## Behaviour
 * - When `queryParamValue` is define
 *  - If Dialog is opened and queryParamValue is not set as action then set action query params as a `queryParamValue` property using `setQueryParams` method of router.
 *  - If Dialog is closed and action query params is equal to `queryParamValue` then history back.
 */

 export const DialogHistoryManager = (baseElement) => class extends baseElement {
    constructor(){
      super();
      this.queryParamValue = '';
      this.queryParamName = 'action';
    }

    static get properties() {
      return {

        /**
         * value of query param on which dialog should be opened.
         */
        queryParamValue: {type: String},

        /**
         * Name of query param, on which dialog should be opened.
         */
        queryParamName: { type: String }

      }
    }

    dialogHistoryManage() {
      if(!this.queryParamValue) {
        return;
      }

      const params = new URLSearchParams(document.location.search);
      const action = params.get(this.queryParamName);

      if (this.opened && action != this.queryParamValue) {
      router.dwRouter.setPageParams({ [this.queryParamName]: this.queryParamValue }, true);
        return;
      }

      if (!this.opened && action == this.queryParamValue) {
        router.dwRouter.back();
      }
    }
  }
