import memoize from 'proxy-memoize';
import get from 'lodash-es/get';
import orderBy from 'lodash-es/orderBy';
import forEach from 'lodash-es/forEach';
import firestoreRedux from '@dreamworld/firestore-redux';

/**
 * @param {Object} state Redux state.
 * @returns {Object} Auth details. e.g. {user: {id, firstName, lastName, roles: ['SYSTEM_ADMIN'], ...}}
 */
export const auth = state => state.auth;

/**
 * Select login user from auth redux state
 */
export const currentUser = (state) => get(state, `auth.user`);

/**
 *
 * @param {Object} state Redux state.
 * @returns {String} Current user id.
 */
export const currentUserId = (state) => get(state, `auth.user.id`);

/**
 * @returns {Boolean} `false` when current logged in user is not Admin.
 */
 export const isAdmin = memoize((state) => {
  const user = currentUser(state) || {};
  return user && user.roles && user.roles.includes('ADMIN');
})


/**
 * @returns {Array} supported languages. e.g. [{code: 'hi', name: 'हिन्दी'}, {code: 'en', name: 'English'}]
 */
export const supportedLanguages = memoize((state) => {
  const systemLanguage = firestoreRedux.selectors.doc(state, `system-language`, `default`);
  if (!systemLanguage || !systemLanguage.langs) {
    return [];
  }

  //Sorting language.
  const systemLangs = orderBy(systemLanguage.langs || [], ['priority ', 'code'], ['desc', 'asc']);

  const _languages = [];
  forEach(systemLangs, (item) => {
    if (!item || item.availability === 'NONE') {
      return true;
    }
    _languages.push({ name: item.name, code: item.code });
  });

  return _languages;
});