import { css } from 'lit';
import { DwIconButton } from '@dreamworld/dw-icon-button/dw-icon-button';

/**
 * Extended version of [dw-icon-button](https://github.com/DreamworldSolutions/dw-icon-button).
 *
 * Diffrent type button support in kerika
 *  - Large
 *    - icon size is 32px and clickable area 48px.
 *    - Example: <kerika-icon-button icon="" large></kerika-icon-button>
 *  - Mediuam
 *    - icon size is 24px and clickable area 40px.
 *    - Example: <kerika-icon-button icon=""></kerika-icon-button>
 *  - Small
 *    - icon size is 20px and clickable area 32px.
 *    - Example: <kerika-icon-button icon="" small></kerika-icon-button>
 */
export class KerikaIconButton extends DwIconButton {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          --dw-icon-button-padding: 8px;
        }

        :host([small]) {
          --dw-icon-button-padding: 6px;
        }

        button {
          background: var(--kerika-icon-button-background, transparent);
        }

        dw-icon {
          background: var(--dw-icon-background, transparent);
          border-radius: var(--dw-icon-border-radius, 0px);
        }

        :host([active]) button::after{
          opacity: 0.12;
        }
    `];
  }

  constructor() {
    super();
    this.iconSize = 24;
    this.buttonSize = 40;
  }

  static get properties() {
    return {
      small: {type: Boolean, reflect: true},
      large: {type: Boolean, reflect: true}
    };
  }


  update(changedProps) {
    if (changedProps.has('small') || changedProps.has('large')) {
      this.iconSize = this.small ? 20 : this.large ? 32 : 24;
      this.buttonSize = this.small ? 32 : this.large ? 48 : 40;
    }
    super.update(changedProps);
  }
}

customElements.define('kerika-icon-button', KerikaIconButton);
