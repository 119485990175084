import { html, css } from '@dreamworld/pwa-helpers/lit';
import { BaseElement } from '../base-element.js';
import { default as i18next } from '../../localize/i18next.js';
import { repeat } from 'lit/directives/repeat.js';

// Redux modules.
import { store } from '../../redux/store.js';
import * as app from '../../redux/app';
import * as router from '../../redux/router';
import * as users from '../../redux/users';
import * as accounts from '../../redux/accounts';
import * as nonProfitDomains from '../../redux/non-profit-domains';
import * as publicDomains from '../../redux/public-domains';
import * as invoiceList from '../../redux/invoice';

// Application logo
import { kerikaText, kerikaLogo } from '../../icons/app.js';

// Elements
import '../common/kerika-navigation-drawer.js';
import '../common/kerika-navigation-drawer-modal.js';
import '../common/kerika-list-item.js';

// Lodash
import get from 'lodash-es/get';

//Add invoice icons.
import { invoice } from '../../icons/app.js';
import { DwIcon } from '@dreamworld/dw-icon';
DwIcon.addIcons({ invoice });
export class AppDrawer extends BaseElement {
  static properties = {
    /**
     * `true` when drawer is opened.
     */
    _opened: { Boolean, state: true },

    /**
     * Navigation list items.
     */
    _items: { type: Array, state: true },

    /**
     * Current page name. Its computed from the router data.
     */
    _pageName: { type: String, state: true },

    /**
     * Current version of the admin app. e.g. 1.0.0
     */
    _version: { state: true }
  };

  static styles = [
    super.styles,
    css`
      :host {
        z-index: 100;
        background: var(--mdc-theme-background);
      }

      kerika-list-item[selected] {
        --mdc-theme-text-primary: var(--mdc-theme-primary);
      }

      .kerika-logo {
        margin: 24px;
        display: flex;
        align-items: center;
        height: 40px;
        width: 152px;
      }

      .kerika-logo .logo,
      .kerika-logo .logo svg {
        height: 32px;
        width: 32px;
      }

      .kerika-logo .logo {
        margin-right: 12px;
      }

      .kerika-logo .text,
      .kerika-logo .text svg {
        width: 94px;
        height: 32px;
      }

    .version {
      text-align: center;
      margin-top: 16px;
      color: var(--mdc-theme-text-disabled);
      padding: 0px 16px;
    }
    `,
  ];

  constructor() {
    super();
    this._setNavigationItems();
  }

  willUpdate(changedProps) {
    super.willUpdate(changedProps);
    // On layout change, if drawer is opened, close it.
    if ((changedProps.has('_layout') || changedProps.has('_screenLayout')) && this._opened) {
      store.dispatch(app.actions.updateDrawerOpened(false));
    }
  }

  render() {
    return html`
      ${this._layout === 'MOBILE' || this._layout === 'TABLET' || this._screenLayout !== 'fullhd'
        ? html`
            <kerika-navigation-drawer-modal @navigation-drawer-changed=${this._onDrawerOpenedChanged} .opened=${this._opened}>
              ${this.contentTemplate}
            </kerika-navigation-drawer-modal>
          `
        : html` <kerika-navigation-drawer .opened=${true}> ${this.contentTemplate} </kerika-navigation-drawer> `}
    `;
  }

  get contentTemplate() {
    return html`
      <div class="kerika-logo">
        <div class="logo">${kerikaLogo}</div>
        <div class="text">${kerikaText}</div>
      </div>

      ${repeat(
        this._items,
        (item) => item.name,
        (item) => {
          return html`
            <kerika-list-item
              id=${item.name}
              @click=${this._resetPageCriteria}
              @selection-changed=${this._onItemSelectionChanged}
              .selected=${item.name === this._pageName}
              .title1=${item.title}
              .leadingIcon=${item.leadingIcon}
              .hasLeadingIcon=${true}
              .leadingIconSymbol=${item.symbol}
            >
            </kerika-list-item>
          `;
        }
      )}
      <div class="version caption">
           ${`${i18next.t('version.title')}:`} <span>${this._version}</span>
      </div>
    `;
  }

  _resetPageCriteria(event) {
    const pageName = event.target.id;
    if(pageName !== this._pageName){
      return;
    }

    switch(pageName) {
      case 'USERS' :
        store.dispatch(users.actions.resetFilters());
        break;
      case 'ACCOUNTS' :
        store.dispatch(accounts.actions.resetFilters());
        break;
      case 'NON_PROFIT_DOMAINS' :
        store.dispatch(nonProfitDomains.actions.updateSortOrder('domain', 'asc'));
        break;
      case 'PUBLIC_DOMAINS' :
        store.dispatch(publicDomains.actions.updateSortOrder('domain', 'asc'));
        break;
      case 'INVOICES' :
        store.dispatch(invoiceList.actions.updateSortOrder('INVOICE_DATE', 'DESC'));
        break;
      default:
        break;
    }

    if(this._layout === 'MOBILE' || this._layout === 'TABLET' || this._screenLayout !== 'fullhd'){
      this._opened = false;
      store.dispatch(app.actions.updateDrawerOpened(this._opened));
    }
  }

  /**
   * Dispatches redux action to update `drawerOpened` status in redux state.
   * @param {Object} e Event of drawer.
   */
  _onDrawerOpenedChanged(e) {
    const target = e.target;
    if (!target || this._opened === target.opened) {
      return;
    }
    this._opened = target.opened;
    store.dispatch(app.actions.updateDrawerOpened(this._opened));
  }

  /**
   * When item is selected, navigates to it's associated page.
   * @param {Object} e `selection-changed` event details.
   */
  _onItemSelectionChanged(e) {
    const el = e.target;
    if (!this._selectionInitiated || !el.selected || !this._opened) {
      this._selectionInitiated = true;
      return;
    }

    router.dwRouter.navigatePage(el.id);

    if (this._layout === 'MOBILE' || this._layout === 'TABLET' || this._screenLayout !== 'fullhd') {
      store.dispatch(app.actions.updateDrawerOpened(false));
    }
  }

  _setNavigationItems() {
    this._items = [
      { name: 'USERS', title: i18next.t('pageTitle.users'), leadingIcon: 'people_alt', symbol: true },
      { name: 'ACCOUNTS', title: i18next.t('pageTitle.accounts'), leadingIcon: 'account_balance', symbol: true },
      { name: 'NON_PROFIT_DOMAINS', title: i18next.t('pageTitle.nonprofitOrganizations'), leadingIcon: 'diversity_1', symbol: true },
      { name: 'PUBLIC_DOMAINS', title: i18next.t('pageTitle.publicDomains'), leadingIcon: 'public', symbol: true },
      { name: 'INVOICES', title: i18next.t('pageTitle.invoices'), leadingIcon: 'invoice', symbol: false },
      { name: 'SYSTEM_LANGUAGE', title: i18next.t('pageTitle.systemLanguage'), leadingIcon: 'language', symbol: true },
      { name: 'MALICIOUS_URL', title: i18next.t('pageTitle.maliciousUrl'), leadingIcon: 'gpp_bad', symbol: true }
    ];
  }

  /**
   * @override
   */
  _setLanguage(newLanguage) {
    super._setLanguage && super._setLanguage(newLanguage);
    this._setNavigationItems();
  }

  stateChanged(state) {
    super.stateChanged && super.stateChanged(state);
    this._opened = app.selectors.drawerOpened(state);
    this._pageName = router.selectors.pageName(state);
    this._version = app.selectors.currentVersion(state);
  }
}
customElements.define('app-drawer', AppDrawer);
