import store from '../store';
import URLs from './urls';
import * as _dwRouter from '@dreamworld/router';
export * as selectors from './selectors.js';

// init routing flow
_dwRouter.init(URLs, store);

// Register fallback callback
_dwRouter.registerFallbackCallback(() => {
  _dwRouter.navigate('/', true);
});

export const dwRouter = _dwRouter;

