import get from 'lodash-es/get';
import find from 'lodash-es/find';
import filter from 'lodash-es/filter';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import sortBy from 'lodash-es/sortBy';
import values from 'lodash-es/values';
import memoize from 'proxy-memoize';
import uniq from 'lodash-es/uniq';
import concat from 'lodash-es/concat';
import { valueFactory } from '@dw/firebase-redux/selectors.js';
import { getUserName } from '../../utils.js'

import * as app from '../app';
import * as firestoreRedux from '@dreamworld/firestore-redux';

export const TABS = { summary: 'SUMMARY', 'billing-history': 'BILLING_HISTORY', team: 'TEAM', 'billing-info': 'BILLING_INFO' };

/**
 * @param {Object} state Redux state
 * @returns {String} Current Tab Name. e.g. 'SUMMARY', 'BILLING_HISTORY', 'TEAM', 'BILLING_INFO'.
 */
export const currentTab = memoize((state) => {
  const tabPathParam = get(state, `router.dialog.params.tab`) || 'summary';
  return TABS[tabPathParam];
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *
 * @returns {Object} Account Summary.
 *  @property {String} id
 *  @property {String} name
 *  @property {Timestamp} createdAt
 *  @property {Timestamp} lastAccessedAt
 *  @property {Object} fileStore e.g. { preferredStore, nextPreferredStore }
 *  @property {String} ownerId
 *  @property {String} ownerEmail
 *  @property {String} ownerName
 *  @property {Array} auths
 *  @property {String} plan
 *  @property {Number} rate
 *  @property {Number} balance
 *  @property {Timestamp} expirationDate
 *  @property {Boolean} autoRenewal
 *  @property {Number} pendingInvoiceCount
 *  @property {Number} activeBoardCount
 *  @property {Number} inActiveBoardCount
 *  @property {Number} pendingInvoiceCount
 *  @property {Number} minimumRequiredSubscriptions
 *  @property {Number} usedAnnualSubscriptions
 *  @property {Number} totalAnnualSubscriptions
 *  @property {Object} team e.g. { admins: { total: 3, pending: 1 }, teamMembers: { total: 5, pending: 0 }, visitors: { total: 7, pending: 0 } }
 */
export const summary = memoize(({ state, accountId }) => {
  const account = firestoreRedux.selectors.doc(state, 'accounts', accountId) || {};
  const fileStore = find(firestoreRedux.selectors.collection(state, 'account-cloud-stores'), { accountId }) || {};
  const lastActivity = find(firestoreRedux.selectors.collection(state, 'account-last-activity'), { accountId }) || {};
  const owner = firestoreRedux.selectors.doc(state, 'users', account.ownerId) || {};
  const _subscription = subscription(state, accountId) || {};
  const pendingInvoices = filter(get(state, `firebase.subscriptions.${accountId}.invoices`), { status: 'PENDING' });
  const boards = filter(firestoreRedux.selectors.collection(state, 'boards'), { accountId });
  const activeBoardCount = filter(boards, { status: 'ACTIVE' }).length;
  const inActiveBoardCount = boards.length - activeBoardCount;

  const pending = !!find(get(state, `firestore.queries`), { requesterId: `summary-${accountId}`, status: 'PENDING' });
  if ((isEmpty(account) || isEmpty(_subscription)) && pending) {
    return;
  }

  // Team tooltip data.
  const allMembers = filter(firestoreRedux.selectors.collection(state, 'account-team-members'), { accountId });

  const totalAdmins = filter(allMembers, { role: 'ACCOUNT_ADMIN' });
  const pendingAdmins = filter(totalAdmins, (member) => !member.joinedAt);

  const totalTeamMembers = filter(allMembers, { role: 'TEAM_MEMBER' });
  const pendingTeamMembers = filter(totalTeamMembers, (member) => !member.joinedAt);

  const totalVisitors = filter(allMembers, { role: 'VISITOR' });
  const pendingVisitors = filter(totalVisitors, (member) => !member.joinedAt);

  const team = {
    admins: { total: totalAdmins.length, pending: pendingAdmins.length },
    teamMembers: { total: totalTeamMembers.length, pending: pendingTeamMembers.length },
    visitors: { total: totalVisitors.length, pending: pendingVisitors.length },
  };

  return {
    id: accountId,
    name: account.name,
    createdAt: account.createdAt,
    lastAccessedAt: lastActivity.updatedAt,
    fileStore,
    ownerId: owner.id,
    ownerEmail: owner.email,
    ownerName: getUserName(owner),
    auths: owner.auths,
    plan: _subscription.plan,
    rate: _subscription.rate,
    balance: _subscription.creditBalance,
    expirationDate: _subscription.expirationDate,
    autoRenewal: _subscription.autoRenewal,
    activeBoardCount,
    inActiveBoardCount,
    pendingInvoiceCount: pendingInvoices.length,
    minimumRequiredSubscriptions: _subscription.minimumRequiredSubscriptions,
    usedAnnualSubscriptions: _subscription.usedAnnualSubscriptions,
    totalAnnualSubscriptions: _subscription.plan === 'SOLO' ? 1 : _subscription.totalAnnualSubscriptions,
    team,
  };
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *
 * @returns {String} Account Name.
 */
export const accountName = memoize(({ state, accountId }) => {
  const listItem = find(get(state, `account-list.result`), { id: accountId }) || {};
  const _account = firestoreRedux.selectors.doc(state, 'accounts', accountId) || {};
  return _account.name || listItem.name || '';
});

/**
 * [Transaction Entity document](https://git.kerika.net/kerikav4/kerika-subscription/-/blob/master/docs/models.md#transaction)
 * [Invoice Entity document](https://git.kerika.net/kerikav4/kerika-subscription/-/blob/master/docs/models.md#invoice)
 *
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *
 * @returns {Array} Billing history of given accountId.
 *  @property {String} id Transaction Id.
 *  @property {String} transactionType
 *  @property {Timestamp} createdAt
 *  @property {String} invoiceId
 *  @property {String} remark
 *  @property {String} description
 *  @property {Number} amount
 *  @property {String} invoiceId
 *  @property {Object} invoice e.g. {offline, status, dueDate, paymentDate, remark, closedBy, closedAt }
 */
export const billingHistory = memoize(({ state, accountId }) => {
  const transactions = get(state, `firebase.subscriptions.${accountId}.transactions`);
  const invoices = get(state, `firebase.subscriptions.${accountId}.invoices`);

  if (invoices === undefined || transactions === undefined) {
    return;
  }

  if (isEmpty(transactions)) {
    return [];
  }

  let list = [];
  forEach(transactions, (transactionModel) => {
    const createdBy = transactionModel.createdBy;
    let user = createdBy ? firestoreRedux.selectors.doc(state, 'users', createdBy) : {};
    if (!transactionModel.invoiceId) {
      let amount = transactionModel && transactionModel.amount || 0;
      amount =  amount ? amount: 0;
      amount = amount ? amount.toFixed(2): '';

      list.push({...transactionModel, ...{amount, user}});
    } else {
      forEach(invoices, (invoiceModel) => {
        if (invoiceModel.id === transactionModel.invoiceId) {
          let amount = invoiceModel.paymentAmount;
          list.push({ ...transactionModel, ...{ invoice: invoiceModel, amount } });
          return false;
        }
      });
    }
  });

  list = sortBy(list, ['createdAt']).reverse();
  return list;
});

/**
 * @params {Object}
 *  @property {Object} state
 *  @property {Number} accountId
 * @return { Array } alphabetical sorted list of account team member details.
 */
export const membersList = memoize(({ state, accountId }) => {
  const allAccTeamMembers = firestoreRedux.selectors.collection(state, 'account-team-members');
  const members = filter(allAccTeamMembers, { accountId });
  const queries = firestoreRedux.selectors.queriesByRequester({ state, requesterId: `team-${accountId}` });
  const pending = !!find(queries, { status: 'PENDING' });
  if (pending && isEmpty(members)) {
    return;
  }

  let list = [];
  forEach(members, (member) => {
    const userId = member.userId;
    let user = firestoreRedux.selectors.doc(state, 'users', userId) || {};
    const profileImage = member.profileImage;
    const name = getUserName(user);
    const email = get(user, 'email', '');
    const role = member.role;
    const joinedAt = member.joinedAt;
    const invitedAt = member.invitedAt;
    const lastSeenDetails = find(firestoreRedux.selectors.collection(state, 'lastseen-details'), { userId }) || {};
    const lastSeenTime = lastSeenDetails.time;
    user = {...user, lastSeenTime };
    const lastLocation = lastSeenDetails.ip;
    const boardsCount = get(state, `manage-account.${accountId}.userWiseBoardCount.${userId}`, 0);
    list.push({ user, userId, profileImage, name, email, role, joinedAt, invitedAt, lastSeenTime, lastLocation, boardsCount });
  });
  return sortBy(list, ['name', 'email']);
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *
 * @returns {Array} List of admin users for given accountId.
 *  @property {Object} user User details.
 *  @property {String} name User full name.
 *  @property {String} email Primary email.
 *  @property {Timestamp} joinedAt
 *  @property {TimeStamp} lastSeenTime
 *  @property {Number} boardsCount
 */
export const adminsList = memoize(({ state, accountId }) => {
  const members = membersList({ state, accountId });
  return !members ? undefined : filter(members, (member) => member.role === 'ACCOUNT_ADMIN');
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *
 * @returns {Array} List of admin users for given accountId.
 *  @property {Object} user User details.
 *  @property {String} name User full name.
 *  @property {String} email Primary email.
 *  @property {Timestamp} joinedAt
 *  @property {TimeStamp} lastSeenTime
 *  @property {Number} boardsCount
 */
export const teamMembersList = memoize(({ state, accountId }) => {
  const members = membersList({ state, accountId });
  return !members ? undefined : filter(members, (member) => member.role === 'TEAM_MEMBER');
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *
 * @returns {Array} List of admin users for given accountId.
 *  @property {Object} user User details.
 *  @property {String} name User full name.
 *  @property {String} email Primary email.
 *  @property {Timestamp} joinedAt
 *  @property {TimeStamp} lastSeenTime
 *  @property {Number} boardsCount
 */
export const visitorsList = memoize(({ state, accountId }) => {
  const members = membersList({ state, accountId });
  return !members ? undefined : filter(members, (member) => member.role === 'VISITOR');
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *  @property {String} userId User Id.
 *
 * @returns {Object}
 *  @property {Object} user User details.
 *  @property {String} name User full name.
 *  @property {String} email Primary email.
 *  @property {Timestamp} joinedAt Account joining time.
 *  @property {Timestamp} lastSeenAt Last Seen time.
 *  @property {String} lastLocation Last Location of the user.
 */
export const memberDetails = memoize(({ state, accountId, userId }) => {
  return find(membersList({ state, accountId }), { userId });
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *  @property {String} userId User Id.
 *
 * @returns {Array}
 *  @property {String} id Board Id.
 *  @property {String} name Board Name.
 *  @property {String} role Role in the board.
 *  @property {Timestamp} lastUpdated Last updated time of the board.
 */
export const userSharedBoards = memoize(({ state, accountId, userId }) => {
  return get(state, `manage-account.${accountId}.userWiseSharedBoards.${userId}`, []);
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *  @property {String} userId User Id.
 *
 * @returns {Number} Count of the boards in which given user is admin.
 */
export const adminBoardsCount = memoize(({ state, accountId, userId }) => {
  return filter(userSharedBoards({ state, accountId, userId }), { role: 'BOARD_ADMIN' }).length;
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *  @property {String} userId User Id.
 *
 * @returns {Number} Count of the boards in which given user is team member.
 */
export const teamMemberBoardsCount = memoize(({ state, accountId, userId }) => {
  return filter(userSharedBoards({ state, accountId, userId }), { role: 'TEAM_MEMBER' }).length;
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *  @property {String} userId User Id.
 *
 * @returns {Number} Count of the boards in which given user is visitor.
 */
export const visitorBoardsCount = memoize(({ state, accountId, userId }) => {
  return filter(userSharedBoards({ state, accountId, userId }), { role: 'VISITOR' }).length;
});

/**
 * @param {Object}
 *  @property {Object} state Redux state
 *  @property {String} accountId Account Id
 *
 * @returns {Object}
 *  @property {String} address
 *  @property {String} city
 *  @property {String} state
 *  @property {String} country
 *  @property {String} organization
 *  @property {String} phone
 *  @property {Number} zip
 */
export const billingAddress = (state, accountId) => {
  return valueFactory(`subscriptions.${accountId}.billing-address`)(state);
};

/**
 * @param {Object} param0
 *  @property {Object} state Redux state.
 *  @property {String} userId User Id.
 * @returns {String} account's stripe subcription url.
 */
export const stripeSubscriptionUrl = ({ state, accountId }) => {
  const { stripeBaseUrl } = app.selectors.config(state);
  const stripeSubscriptionId = get(state, `firebase.subscriptions.${accountId}.subscription.stripeSubscriptionId`);
  return stripeSubscriptionId ? `${stripeBaseUrl}/subscriptions/${stripeSubscriptionId}` : '';
};

/**
 * @returns {Array} Account's billing emails including account owner's primary email address.
 */
export const billingEmails = (state, accountId) => {
  const account = firestoreRedux.selectors.doc(state, 'accounts', accountId);
  const ownerId = account && account.ownerId;
  const owner = firestoreRedux.selectors.doc(state, 'users', ownerId);
  const ownerEmail = owner && owner.email;

  const _billingAddress = billingAddress( state, accountId );
  let billingEmails = get(_billingAddress, 'billingEmails');
  billingEmails = Array.isArray(billingEmails) ? billingEmails : values(billingEmails);
  return uniq([ownerEmail, ...billingEmails]);
};


/**
 * @param {Object} state Redux state.
 * @param {String} accountId
 * @returns {Object} given account subcription details.
 */
export const subscription = (state, accountId) => {
  return valueFactory(`subscriptions.${accountId}.subscription`)(state);
};

/**
 * @param {Object} state Redux state.
 * @param {String} accountId
 * @returns {Number} given account free sunscriptions.
 */
export const freeSubscriptions = (state, accountId) => {
  const _subscription = subscription(state, accountId);
  const totalAnnualSubscriptions = _subscription && _subscription.totalAnnualSubscriptions || 0;
  const usedAnnualSubscriptions = _subscription && _subscription.usedAnnualSubscriptions || 0;
  const _freeSubscriptions = totalAnnualSubscriptions - usedAnnualSubscriptions;
  return _freeSubscriptions > 0 ? _freeSubscriptions: 0;
};

/**
 * @param {Object} state Redux state.
 * @param {String} accountId
 * @returns {Number} given account subscriptions plan.
 */
export const plan = (state, accountId) => {
  const _subscription = subscription(state, accountId);
  return _subscription && _subscription.plan;
};

/**
 * @param {Object} state Redux state.
 * @param {String} accountId Account Id.
 * @returns {String} Account team export as excel link.
 */
export const teamExportLink = (state, accountId) => {
  const { apiBaseUrl } = app.selectors.config(state);
  return `${apiBaseUrl}/account/accounts/${accountId}/team/export`;
}
