import { getAuth, signInWithCustomToken, signOut, onAuthStateChanged } from 'firebase/auth';
import * as app from '../app/index.js';
import * as auth from '../auth';
import { store } from '../store';
import firestoreRedux from '@dreamworld/firestore-redux';
import isEqual from 'lodash-es/isEqual';
import fetch from '../../fetch.js';

export const UPDATE_AUTH = 'UPDATE_AUTH';

/**
 * On success, sets app connection status to `true`, while on failure sets it to `false`
 * When request is failed or auth token is not found, sets auth to `null`.
 * @returns {String} Firebase auth token.
 */
const getFirebaseAuthToken = async () => {
  try {
    const { token } = await fetch(`/user/users/me/firebase-auth-token`);
    if (!token) {
      resetAuthAndNavigateToLoginPage();
    }
    return token;
  } catch (error) {
    //Do nothing if application is an under maintenance
    if (error && error.status == 502) {
      return;
    }
    resetAuthAndNavigateToLoginPage();
  }
};

/**
 * Sign in using firebase auth token.
 * @param {String} token - Sign in into firebase auth using firebase-auth custom token
 */
const signInIntoFirebaseAuth = async (token) => {
  try {
    await signInWithCustomToken(getAuth(), token);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(`Failed to signIn into firebase > code: ${errorCode}, message: ${errorMessage}`);
    resetAuthAndNavigateToLoginPage();
  }
};

/**
 * Fetch auth detail & user's attrs, updates auth state.
 * @retunr - Promise
 */
const getCurrentUserInfo = async () => {
  try {
    const user = await fetch(`/user/users/me`);
    const currentUser = auth.selectors.currentUser(store.getState());
    if (!isEqual(currentUser, user)) {
      store.dispatch(updateAuth(user));
    }
  } catch (error) {
    throw `Failed to get current user info: ${error}`;
  }
};

/**
 * Sign out from firebase auth.
 */
export const signOutFromFirebaseAuth = async () => {
  const auth = getAuth();
  return signOut(auth);
};

/**
 * Gets firebase auth token from the server.
 * Sign In with Firebase
 * Gets Current user details from the server & stores it in redux store.
 */
export const init = async () => {
  //Listen on authenticatin denied action to reset previous authentication state.
  window.addEventListener('authentication-denied', resetAuthAndNavigateToLoginPage);

  onAuthStateChanged(getAuth(), async (firebaseUser) => {
    const user = auth.selectors.currentUser(store.getState());
    // When user is logged in in firebase & persisted auth exists.
    if (firebaseUser && user) {
      store.dispatch(updateAuth(user));
      getCurrentUserInfo();
    } else {
      // When user is not logged in.
      const token = await getFirebaseAuthToken();
      if (!token) {
        return;
      }
      await signInIntoFirebaseAuth(token);
      getCurrentUserInfo();
    }
  });
  firestoreRedux.query('app');

  //Load system language
  firestoreRedux.getDocById(`system-language`, `default`);
};

/**
 * Logout from firebase auth and reset auth data in redux state and local storage
 * Logout user from auth and webapp to using end-point
 */
export const logout = async () => {
  await signOutFromFirebaseAuth();
  await fetch(`/user/logout`);
  resetAuthAndNavigateToLoginPage();
};

/**
 * Resets `auth` state to `null` & redirects to login page with redirect url.
 */
const resetAuthAndNavigateToLoginPage = () => {
  const config = app.selectors.config(store.getState());
  store.dispatch(updateAuth(null));
  location.href = `${config.pwaBaseUrl}/login?redirect=${encodeURIComponent(location.href)}&hide-signup=true`;
};

/**
 *
 * @param {Object} user - User detail
 * @retur {Object} - Redux 'UPDATE_AUTH' action to update auth detail
 */
export const updateAuth = (user) => {
  return {
    type: UPDATE_AUTH,
    user,
  };
};
