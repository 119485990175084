import { default as installWorkbox } from '@dreamworld/workbox-installer';
import FirestoreLatestVersionUpdateChecker from '@dreamworld/workbox-installer/firestore-latest-version-update-checker';
import { store } from './redux/store.js';
import * as app from './redux/app';
import { show as showSnackBar } from './components/common/kerika-snackbar.js';
import i18next from './localize/i18next.js';
import once from 'lodash-es/once';

/**
 * Shows toast message when new version is available.
 * @returns {Promise} Which is resolved on click of `Update` button in toast.
 */
const confirmUpdate = once(() => {
  let confirmResolve;

  showSnackBar({
    message: i18next.t('toast.newVersionAvailable'),
    timeout: 0,
    actionButton: {
      caption: i18next.t('buttons.update'),
      callback: () => {
        confirmResolve();
      },
    },
  });

  return new Promise((resolve) => {
    confirmResolve = resolve;
  });
});

export const installServiceWorker = async (firebaseApp) => {
  const config = app.selectors.config(store.getState());
  const fsLatestVersionUpdateChecker = new FirestoreLatestVersionUpdateChecker({
    latestVersionDocumentPath: config.serviceWorker.latestVersionDocumentPath,
    latestVersionField: config.serviceWorker.latestVersionField,
    curVersion: config.version,
    firebaseApp
  });
  installWorkbox({
    url: '/sw.js',
    confirmUpdate,
    updateChecker: fsLatestVersionUpdateChecker,
  });
};
