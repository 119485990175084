//Lodash
import get from 'lodash-es/get';
import filter from 'lodash-es/filter';
import merge from 'lodash-es/merge';
import memoize from 'proxy-memoize';
import cloneDeep from 'lodash-es/cloneDeep';
import { valueFactory } from '@dw/firebase-redux/selectors.js';

import { getUserName } from '../../utils.js';

import * as app from '../app';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as manageAccount from '../manage-account';

/**
 * @returns {Array} list of invoices. e.g. [{ accountName, ...{Invoice} }, ...]
 */
export const list = memoize((state) => {
  let _result = get(state, `invoice-list.result`);
  if (!_result) {
    return;
  }

  _result = cloneDeep(_result);
  // When no records found.
  if (_result && !_result.length) {
    return _result;
  }

  return _result;
});

/**
 * @returns {Array} list of open invoices. e.g. [{ accountName, ...{Invoice} }, ...]
 */
export const openInvoices = memoize((state) => {
  const _result = list(state);
  if (!_result) {
    return;
  }

  // When no records found.
  if (_result && !_result.length) {
    return _result;
  }

  return filter(_result, (item) => {return item && item.status === 'PENDING';});
});

/**
 * @returns {Array} list of close invoices. e.g. [{ accountName, ...{Invoice} }, ...]
 */
export const closeInvoices = memoize((state) => {
  const _result = list(state);
  if (!_result) {
    return;
  }

  // When no records found.
  if (_result && !_result.length) {
    return _result;
  }

  return filter(_result, (item)=> { return item && (item.status === 'PAID' || item.status === 'CLOSED')});
});

/**
 * @param {Object} state Redux state.
 * @returns {Number} Last request page number.
 */
export const lastRequestPage = (state) => get(state, `invoice-list.lastRequest.page`);

/**
 *
 * @param { Object } state Redux state
 * @returns { String } status of the last request.
 */
export const lastRequestStatus = (state) => get(state, `invoice-list.lastRequest.status`);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when request is in-progress.
 */
export const requestInProgress = (state) => {
  const _lastRequestStatus = lastRequestStatus(state);
  return _lastRequestStatus === 'IN_PROGRESS';
};

/**
 * @override
 * @returns {Number} Records per page to be loaded.
 */
export const recordsPerPage = app.selectors.recordsPerPage;

/**
 * @returns { Boolean } `true` when all the records are loaded.
 */
export const eol = memoize((state) => {
  const _list = list(state) || [];
  const _rpp = recordsPerPage(state);
  const _lastRequestPage = lastRequestPage(state);
  const _lastRequestStatus = lastRequestStatus(state);
  return _lastRequestStatus === 'SUCCESS' && _rpp * _lastRequestPage > _list.length;
});

/**
 * @param {Object} state Redux state.
 * @returns {String} Sort By field name.
 */
export const sortBy = (state) => get(state, `invoice-list.sortBy`, 'INVOICE_DATE');

/**
 * @param {Object} state Redux state.
 * @returns {String} Sort order of the list.
 */
export const sortOrder = (state) => get(state, `invoice-list.sortOrder`, 'DESC');


/**
 * @param {Object} state Redux state.
 * @param {String} accountId
 * @param {String} invoiceId
 * @returns {Object} given invoice details.
 *  e.g {...{Invoice}, accountName, ownerName, ownerEmail}
 */
export const invoice = (state, accountId, invoiceId) => {
  let _invoice = valueFactory(`subscriptions.${accountId}.invoices.${invoiceId}`)(state);
  const _account = firestoreRedux.selectors.doc(state, 'accounts', accountId);
  const _owner = _account && _account.ownerId ? firestoreRedux.selectors.doc(state, 'users', _account.ownerId): undefined;
  if(_invoice === undefined || _account === undefined || _owner === undefined) {
    return undefined;
  }

  _invoice = _invoice || {};
  return merge({}, _invoice, {accountName: _account && _account.name || '',  ownerEmail: _owner.email || '', ownerName: getUserName(_owner)});
};


/**
 * @param {Object} state Redux state.
 * @param {String} accountId
 * @param {String} invoiceId
 * @returns {Boolean} given invoice delete is possible or not.
 */
export const canDelete = memoize(({state, invoiceId, accountId}) => {
  const _invoice = invoice(state, accountId, invoiceId);
  const freeSubscriptions = manageAccount.selectors.freeSubscriptions(state, accountId);
  const plan = manageAccount.selectors.plan(state, accountId);
  if(!_invoice || _invoice.status !== 'PENDING' || !_invoice.annualSubscriptions) {
    return false;
  }

  if(plan === 'TRIAL') {
    return true;
  }

  return _invoice.annualSubscriptions <= freeSubscriptions;
});
