import get from 'lodash-es/get';
import memoize from 'proxy-memoize';
import device from "current-device";
import * as firestoreRedux from '@dreamworld/firestore-redux';

/**
 *
 * @param {Object} state Redux state.
 * @returns {Object} app config.
 */
export const config = (state) => get(state, `app.config`);

/**
 * @param { Object } state Redux state detail object.
 *  @returns { Boolean } `true` when current device is desktop.
 */
export const isDesktopDevice = (state) => {
  return device.desktop();
};

/**
 * @param { Object } state Redux state detail object.
 *  @returns { Boolean } `true` when current device is mobile.
 */
export const isMobileDevice = (state) => {
  return device.mobile() && !isTabletDevice();
};

/**
 * @param { Object } state Redux state detail object.
 *  @returns { Boolean } `true` when current device is tablet.
 */
export const isTabletDevice = (state) => {
  return device.tablet();
};

/**
 * @param { Object } state Redux state detail object.
 * @returns { String } layout based on the screen.
 */
export const screenLayout = (state) => {
  return get(state, 'device-info.layout');
};

/**
 * @param { Object } state Redux state detail object.
 * @returns { Boolean } current device is touch device or not.
 */
export const isTouchDevice = (state) => {
  return get(state, 'device-info.touch') === true;
};

/**
 * @returns {String} layout based on the device. Possible values: 'MOBILE', 'TABLET' or 'DESKTOP'
 */
export const layout = memoize((state) => {
  const _screenLayout = screenLayout(state);
  if (_screenLayout === 'small') {
    return 'MOBILE';
  }

  if (_screenLayout === 'medium' || _screenLayout === 'large') {
    return 'TABLET';
  }
  return 'DESKTOP';
});

/**
 * @returns {Boolean} `true` when drawer is opened.
 */
export const drawerOpened = memoize((state) => {
  const _layout = layout(state);
  const _screenLayout = screenLayout(state);
  if (_layout === 'MOBILE' || _layout === 'TABLET' || _screenLayout !== 'fullhd') {
    return get(state, `app.drawerOpened`);
  }

  return true;
});

/**
 * @returns {Number} Number of records per page. Its based on the current layout.
 */
export const recordsPerPage = (state) => {
  return 50;
};

/**
 * @param {Object} state Redux state.
 * @returns {Boolean} `true` when app is under maintenance.3
 */
export const isUnderMaintenance = (state) => {
  const maintenanceMode = firestoreRedux.selectors.doc(state, 'app', 'maintenance-mode');
  return get(maintenanceMode, 'active');
};

/**
 * @returns {String} Current version of the admin app. e.g. 1.0.0
 */
export const currentVersion = memoize((state) => {
  const _config = config(state);
  return get(_config, 'version', '');
});
