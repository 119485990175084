import * as actions from './actions.js';

let config = window.K.config || {};
const serviceWorker = { latestVersionDocumentPath: 'app/app-admin', latestVersionField: 'latestVersion' };
config = { addNewMaliciousUrlDoc: 'https://docs.google.com/document/d/15SO-9kAR17YlwLrLWnNA_MsOlgHiCquhi883-ZXuTeI/edit', ...config, serviceWorker };
const INITIAL_STATE = { config };

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE_DRAWER_OPENED:
      return { ...state, drawerOpened: action.opened };

    default:
      return state;
  }
};

export default app;
