export const LOAD = 'PUBLIC_DOMAIN_LOAD_LIST';
export const LOAD_DONE = 'PUBLIC_DOMAIN_LOAD_LIST_DONE';
export const LOAD_FAILED = 'PUBLIC_DOMAIN_LOAD_LIST_FAILED';
export const LOAD_ACCOUNTS_USERS = 'PUBLIC_DOMAIN_LOAD_ACCOUNTS_USERS';
export const LOAD_ACCOUNTS_USERS_DONE = 'PUBLIC_DOMAIN_LOAD_ACCOUNTS_USERS_DONE';
export const LOAD_ACCOUNTS_USERS_FAILED = 'PUBLIC_DOMAIN_LOAD_ACCOUNTS_USERS_FAILED';
export const ADD = 'PUBLIC_DOMAIN_ADD';
export const ADD_FAILED = 'PUBLIC_DOMAIN_ADD_FAILED';
export const ADD_DONE = 'PUBLIC_DOMAIN_ADD_DONE';
export const REMOVE = 'PUBLIC_DOMAIN_REMOVE';
export const REMOVE_FAILED = 'PUBLIC_DOMAIN_REMOVE_FAILED';
export const REMOVE_DONE = 'PUBLIC_DOMAIN_REMOVE_DONE';
export const UPDATE_SORT_ORDER = 'PUBLIC_DOMAIN_UPDATE_SORT_ORDER';
export const UPDATE_ACCOUNTS_SORT_ORDER = 'PUBLIC_DOMAIN_UPDATE_ACCOUNTS_SORT_ORDER';
export const UPDATE_USERS_SORT_ORDER = 'PUBLIC_DOMAIN_UPDATE_USERS_SORT_ORDER';
export const EXPORT_DOMAINS = 'PUBLIC_DOMAIN_EXPORT_DOMAINS';
export const EXPORT_ACCOUNTS_AND_USERS = 'PUBLIC_DOMAIN_EXPORT_ACCOUNTS_AND_USERS';

/**
 * Requests to load public domains list.
 */
export const _load = () => {
  return {
    type: LOAD,
  };
};

/**
 * Stores public domains into redux state.
 * @param {Array} list List of public domains.
 */
export const _loadDone = (list) => {
  return {
    type: LOAD_DONE,
    list,
  };
};

/**
 * Updates last request status to failed in redux state.
 * @param {String} error Error code.
 */
export const _loadFailed = (error) => {
  return {
    type: LOAD_FAILED,
    error,
  };
};

/**
 * Requests to load given domain's accounts & users.
 * @param {String} domain Domain
 */
export const loadAccountsUsers = (domain) => {
  return {
    type: LOAD_ACCOUNTS_USERS,
    domain,
  };
};

/**
 *
 * @param {String} domain Domain
 * @param {Object} result Domain's accounts & users list. e.g. { accounts, users }
 */
export const _loadAccountsUsersDone = (domain, result) => {
  return {
    type: LOAD_ACCOUNTS_USERS_DONE,
    domain,
    result,
  };
};

/**
 *
 * @param {String} domain
 * @param {String} error Error code.
 */
export const _loadAccountsUsersFailed = (domain, error) => {
  return {
    type: LOAD_ACCOUNTS_USERS_FAILED,
    domain,
    error,
  };
};

/**
 * Requests to add new public domain.
 * @param {String} domain
 */
export const add = (domain, addedBy) => {
  return {
    type: ADD,
    domain,
    addedBy
  };
};

/**
 * Removes locally added domain from the list.
 * @param {String} domain
 */
export const _addFailed = (domain, error) => {
  return {
    type: ADD_FAILED,
    domain,
    error
  };
};

export const _addDone = (domain) => {
  return {
    type: ADD_DONE,
    domain
  };
};

/**
 * Requests to remove domain from public list.
 * @param {String} domain
 */
export const remove = (domain) => {
  return {
    type: REMOVE,
    domain,
  };
};

/**
 * Dispatched when remove request is failed.
 * Restores deleted domain.
 * @param {Object} domain domain document.
 */
export const _removeFailed = (domain, error) => {
  return {
    type: REMOVE_FAILED,
    domain,
    error
  };
};

export const _removeDone = (domain) => {
  return {
    type: REMOVE_DONE,
    domain
  };
};

/**
 * updates sort order of domains list.
 * @param {String} sortBy sortBy field.
 * @param {String} sortOrder Sort order.
 */
export const updateSortOrder = (sortBy, sortOrder) => {
  return {
    type: UPDATE_SORT_ORDER,
    sortBy,
    sortOrder,
  };
};

/**
 * updates sort order of accounts list.
 * @param {String} sortBy sortBy field.
 * @param {String} sortOrder Sort order.
 */
export const updateAccountsSortOrder = (sortBy, sortOrder) => {
  return {
    type: UPDATE_ACCOUNTS_SORT_ORDER,
    sortBy,
    sortOrder
  }
}

/**
 * updates sort order of users list.
 * @param {String} sortBy sortBy field.
 * @param {String} sortOrder Sort order.
 */
 export const updateUsersSortOrder = (sortBy, sortOrder) => {
  return {
    type: UPDATE_USERS_SORT_ORDER,
    sortBy,
    sortOrder
  }
}

/**
 * Requests to export given domain's accounts & users as an excel sheet.
 * @param {String} domain Domain
 */
export const exportAccountsAndUsers = (domain) => {
  return {
    type: EXPORT_ACCOUNTS_AND_USERS,
    domain,
  };
};
