export const LOAD_TAB_DETAIL = 'MANAGE_ACCOUNT_LOAD_TAB_DETAIL';
export const DISCONNECT_TAB_DETAIL = 'MANAGE_ACCOUNT_DISCONNECT_TAB_DETAIL';
export const USER_WISE_BOARD_COUNT_LOADED = 'MANAGE_ACCOUNT_USER_WISE_BOARD_COUNT_LOADED';
export const USER_SHARED_BOARDS_LOADED = 'MANAGE_USER_SHARED_BOARDS_LOADED';
export const RENAME = 'MANAGE_ACCOUNT_RENAME';
export const DELETE = 'MANAGE_ACCOUNT_DELETE';
export const UPDATE_BILLING_INFO = 'MANAGE_ACCOUNT_UPDATE_BILLING_INFO';

/**
 * Loads data of given tab.
 * @param {String} tabName Tab name. e.g. 'SUMMARY', 'BILLING_HISTORY', 'TEAM', 'BILLING_INFO'.
 */
export const _loadTabDetail = (tabName) => {
  return {
    type: LOAD_TAB_DETAIL,
    tabName,
  };
};

/**
 * Disconnects data from firestore/fiebase for given tabName.
 * @param {String} tabName Tab name. e.g. 'SUMMARY', 'BILLING_HISTORY', 'TEAM', 'BILLING_INFO'.
 */
export const _disconnectTabDetail = (tabName) => {
  return {
    type: DISCONNECT_TAB_DETAIL,
    tabName,
  };
};

/**
 * Stores `userWiseBoardCount` in redux state.
 * @param {String} accountId Account Id. e.g. 'acc_4fj94959599994v99'.
 * @param {Object} userWiseBoardCount User wise board's count. e.g. { 'usr_w4234jfjf': 3 }
 */
export const _userWiseBoardCountLoaded = (accountId, userWiseBoardCount) => {
  return {
    type: USER_WISE_BOARD_COUNT_LOADED,
    accountId,
    userWiseBoardCount,
  };
};

/**
 *
 * @param {String} accountId Account Id.
 * @param {String} userId User Id.
 * @param {Array} sharedBoards Shared boards of the given user.
 */
export const _userSharedBoardsLoaded = (accountId, userId, sharedBoards) => {
  return {
    type: USER_SHARED_BOARDS_LOADED,
    accountId,
    userId,
    sharedBoards,
  };
};

/**
 * Renames account name.
 * @param {String} id Account Id.
 * @param {String} name Account Name.
 */
export const rename = (id, name) => {
  return {
    type: RENAME,
    id,
    name,
  };
};

/**
 * Requests to delete account.
 * @param {String} id Account Id.
 */
export const deleteAccount = (id) => {
  return {
    type: DELETE,
    id,
  };
};

/**
 * Requests to update billig address.
 * @param {String} accountId Account Id.
 * @param {Object} billingInfo Account billing info.
 *  @property {String} address
 *  @property {String} city
 *  @property {String} state
 *  @property {String} country
 *  @property {String} organization
 *  @property {String} phone
 *  @property {Number} zip
 */
export const updateBillingInfo = (accountId, billingInfo) => {
  return {
    type: UPDATE_BILLING_INFO,
    accountId,
    billingInfo,
  };
};

