import { LinkStyle } from '@dreamworld/material-styles/link.js';
import typographyLiterals from './typography-literals.js';
import { css, unsafeCSS } from 'lit';

export const shimmerStyle = css`
  background: #777;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`;

export const sharedStyles = css`
  ${unsafeCSS(LinkStyle)}

  :host {
    display: block;
  }

  :host,
  :host * {
    box-sizing: border-box;
  }

  /* hide hidden el on hidden attribute */
  :host([hidden]),
  [hidden],
  *[hidden] {
    display: none !important;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .empty-label {
    color: var(--empty-label-text-color)
  }

  .uppercase {
    text-transform: uppercase !important;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: var(--mdc-theme-primary);
  }

  .confirm-input-label {
    font-family: 'Courier New', Courier;
    font-style: normal;
    font-weight: bold;
    color: var(--mdc-theme-primary);
    letter-spacing: 2px;
    margin-left: 4px;
  }

  .bold,
  strong {
    font-weight: 500;
  }

  hr {
    border-color: var(--divider-color);
    border-width: 1px 0 0 0;
    margin: 8px 0px;
  }

  .empty-message {
    ${typographyLiterals.headline6};
    font-weight: 400;
  }

  .placeholder-message {
    color: var(--placeholder-message-color, rgba(0, 0, 0, 0.38));
    ${typographyLiterals.headline6};
  }

  .dialog-placeholder-message {
    color: var(--dialog-placeholder-message-color, rgba(0, 0, 0, 0.38));
    ${typographyLiterals.body1};
  }

  .shimmer {
    ${unsafeCSS(shimmerStyle)};
  }

  @keyframes shimmer {
    100% {
      -webkit-mask-position: left;
    }
  }

  :host([layout='MOBILE']) kerika-action-toolbar {
    --dw-select-bg-color: var(--mdc-theme-surface);
  }

  // START: Scrollbar styles
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.32) transparent;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.32);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: calc(12px / 2);
  }
  // END: Scrollbar styles
`;

export default sharedStyles;
