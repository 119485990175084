import i18next from '../localize/i18next.js';
import localize from '../localize/localize.js';
import { LitElement } from '@dreamworld/pwa-helpers/lit';
import { connect } from '@dreamworld/pwa-helpers/connect-mixin.js';
import store from '../redux/store.js';
import * as app from '../redux/app';

//Theme Imports
import sharedStyles from '../theme/shared-styles.js';
import typography from '../theme/typography.js';

export * from 'lit';

/**
 * Base connected element with `layout` attribute, shared styles & typography.
 */
export class BaseElement extends connect(store)(localize(i18next)(LitElement)) {
  static styles = [sharedStyles, typography];

  static properties = {
    _layout: {
      type: String,
      reflect: true,
      attribute: 'layout',
    },

    _screenLayout: {
      type: String,
      reflect: true,
      attribute: 'screen-layout',
    },

    /**
     * current device is touch device or not.
     */
    _isTouchDevice: {
      type: Boolean,
      reflect: true,
      attribute: 'touch-device',
    },
  };

  stateChanged(state) {
    super.stateChanged && super.stateChanged(state);
    this._layout = app.selectors.layout(state);
    this._screenLayout = app.selectors.screenLayout(state);
    this._isTouchDevice = app.selectors.isTouchDevice(state);
  }
}
customElements.define('base-element', BaseElement);
