import { LitElement, html, css } from '@dreamworld/pwa-helpers/lit.js';
import { default as i18next, init as i18nextInit } from '../../localize/i18next.js';
import { kerikaText, kerikaLogo } from '../../icons/app.js';
import typography from '../../theme/typography.js';
import sharedStyles from '../../theme/shared-styles.js';

/**
 * Shows app splash screen when state of the app is not known.
 *
 * Usage pattenr:
 *  - <splash-screen></splash-screen>
 */
export class SplashScreen extends LitElement {
  static styles = [
    typography,
    sharedStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
      }

      .kerika-text, .kerika-logo, .get-more-done {
        display: flex;
        justify-content: center;
      }

      .kerika-logo svg {
        width: 98px;
        height: 98px;
        margin: 12px 0px;
      }


      .kerika-text svg {
        height: 52px;
        width: 150px;
      }

      .get-more-done {
        color: var(--app-logo-color);
        margin: 8px 0px;
        font-weight: 300;
      }
    `
  ];

  constructor(){
    super();
    this._title = 'Get More Done';
  }

  static properties = {
    _title: { type: String }
  }

  _setLanguage(language){
    super._setLanguage(language);
    this._title = i18next.t("splashScreen.title");
  }

  render() {
    return html`
      <div class="kerika-logo">${kerikaLogo}</div>
      <div class="kerika-text">${kerikaText}</div>
      <div class="headline4 get-more-done">${this._title}</div>
    `;
  }
}
customElements.define('splash-screen', SplashScreen);
