export const LOAD_NEXT_PAGE = 'INVOICE_LOAD_NEXT_PAGE';
export const REQUEST_LIST = 'INVOICE_REQUEST_LIST';
export const REQUEST_LIST_SUCCEED = 'INVOICE_REQUEST_LIST_SUCCEED';
export const REQUEST_LIST_FAILED = 'INVOICE_REQUEST_LIST_FAILED';
export const REQUEST_LIST_CANCEL = 'INVOICE_REQUEST_LIST_CANCEL';
export const REQUEST_LIST_IGNORE = 'INVOICE_REQUEST_LIST_IGNORE';

export const REFRESH_LIST = 'INVOICE_REFRESH_LIST';
export const REFRESH_LIST_SUCCEED = 'INVOICE_REFRESH_LIST_SUCCEED';
export const REFRESH_LIST_FAILED = 'INVOICE_REFRESH_LIST_FAILED';

export const UPDATE_SORT_ORDER = 'INVOICE_UPDATE_SORT_ORDER';
export const DOWNLOAD = 'INVOICE_DOWNLOAD';
export const CHANGE_DUE_DATE = 'INVOICE_CHANGE_DUE_DATE';
export const CHANGE_SUBSCRIPTIONS = 'INVOICE_CHANGE_SUBSCRIPTIONS';
export const CHANGE_REMARKS = 'INVOICE_CHANGE_REMARKS';
export const MARK_AS_CLOSED = 'INVOICE_MARK_AS_CLOSED';
export const MARK_AS_PAID = 'INVOICE_MARK_AS_PAID';
export const INVOICE_DELETE = 'INVOICE_DELETE';
export const UPDATE_ADDRESS = `INVOICE_UPDATE_ADDRESS`

/**
 * Loads next page based on lastRequest & sorting criteria.
 * When previous request is in-progress, does not request to load data from server.
 */
export const loadNextPage = () => {
  return {
    type: LOAD_NEXT_PAGE,
  };
};

/**
 * Requests to get invoice list based on  lastRequest & sorting criteria.
 * Updates `lastRequest.status` to `IN_PROGRESS`
 * @param {Boolean} nextPage When it's `true` requests for next page otherwise loads 1st page records.
 */
export const _requestList = (nextPage) => {
  return {
    type: REQUEST_LIST,
    nextPage,
  };
};

/**
 * Dispatched when list request succeed.
 * Updates result, lastRequest details.
 * @param {Array} data List of invoice
 * @param {Boolean} nextPage When `true`, appends data to current list otherwise overrides result with current data.
 * @returns
 */
export const _requestListSucceed = (data, nextPage) => {
  return {
    type: REQUEST_LIST_SUCCEED,
    data,
    nextPage,
  };
};

/**
 * Dispatched when list request failed with any reason.
 * Updates `lastRequest`'s status to `FAILED`.
 * @param {Object} error Error code
 */
export const _requestListFailed = (error) => {
  return {
    type: REQUEST_LIST_FAILED,
    error,
  };
};

/**
 * Requests to refresh invoice list based on lastRequest & sorting criteria.
 */
export const _refreshList = () => {
  return {
    type: REFRESH_LIST,
  };
};

/**
 * Dispatched when list refresh succeed.
 * Updates result details.
 * @param {Array} data List of invoice
 * @returns
 */
export const _refreshListSucceed = (data) => {
  return {
    type: REFRESH_LIST_SUCCEED,
    data
  };
};

/**
 * Dispatched when list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshListFailed = (error) => {
  return {
    type: REFRESH_LIST_FAILED,
    error,
  };
};

/**
 * Cancels the last `in-progress` request.
 * @param {String} reason Reason to cancel the request. Possible values: 'UPDATE_SORT_ORDER', 'PAGE_CLOSED'
 */
export const _requestListCancel = (reason) => {
  return {
    type: REQUEST_LIST_CANCEL,
    reason,
  };
};

/**
 * This action is just for notification into redux-devtools.
 * It's dispatched when previous request is already in-progress & view component requests to load next page.
 */
export const _requestListIgnore = () => {
  return {
    type: REQUEST_LIST_IGNORE,
  };
};

/**
 * Updates sort criteria.
 * @param {String} sortBy Sort By field name. e.g. 'ACCOUNT_NAME' or 'INVOICE_DATE' or 'DUE_DATE'
 * @param {String} sortOrder Sort order. e.g. 'ASC' or 'DESC'
 */
export const updateSortOrder = (sortBy, sortOrder) => {
  return {
    type: UPDATE_SORT_ORDER,
    sortBy,
    sortOrder,
  };
};

/**
 * Download an given invoice
 * @param {String} accountId
 * @param {String} invoiceId
 */
export const download = (accountId, invoiceId) => {
  return {
    type: DOWNLOAD,
    accountId,
    invoiceId,
  };
};

/**
 * change due-date of given invoice
 * @param {String} accountId
 * @param {String} invoiceId
 * @param {Timestamp} dueDate
 */
export const changeDueDate = (accountId, invoiceId, dueDate) => {
  return {
    type: CHANGE_DUE_DATE,
    accountId,
    invoiceId,
    dueDate
  };
};

/**
 * change remarks of given invoice
 * @param {String} accountId
 * @param {String} invoiceId
 * @param {String} remarks
 */
export const changeRemarks = (accountId, invoiceId, remarks) => {
  return {
    type: CHANGE_REMARKS,
    accountId,
    invoiceId,
    remarks
  };
};

/**
 * change subscriptions of given invoice
 * @param {String} accountId
 * @param {String} invoiceId
 * @param {String} remarks
 * @param {Number} subscriptions
 */
export const changeSubscriptions = ({accountId, invoiceId, remarks, subscriptions}) => {
  return {
    type: CHANGE_SUBSCRIPTIONS,
    accountId,
    invoiceId,
    remarks,
    subscriptions
  };
};

/**
 * Mark as closed an given invoice
 * @param {String} accountId
 * @param {String} invoiceId
 * @param {String} remarks
 */
export const markAsClosed = (accountId, invoiceId, remarks) => {
  return {
    type: MARK_AS_CLOSED,
    accountId,
    invoiceId,
    remarks
  };
};

/**
 * Mark as paid an given invoice
 * @param {String} accountId
 * @param {String} invoiceId
 * @param {String} remarks
 */
export const markAsPaid = (accountId, invoiceId, remarks) => {
  return {
    type: MARK_AS_PAID,
    accountId,
    invoiceId,
    remarks
  };
};

/**
 * Delete an given invoice
 * @param {String} invoiceId
 */
export const deleteInvoice = (accountId, invoiceId) => {
  return {
    type: INVOICE_DELETE,
    accountId,
    invoiceId
  };
};

/**
 * Update invoice billing-address.
 * @param {String} accountId
 * @param {String} invoiceId
 * @param {Object} billingAddress
 * @param {Number} paymentAmount
 * @param {Number} washingtonTax
 */
export const updateAddress = ({accountId, invoiceId, billingAddress, paymentAmount, washingtonTax}) => {
  return {
    type: UPDATE_ADDRESS,
    accountId,
    invoiceId,
    paymentAmount,
    billingAddress,
    washingtonTax
  }
}