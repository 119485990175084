import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';

/**
 * Currently supported language.
 */
export const supportedLanguage = ['hi', 'en'];

/**
 * Get current language.
 * Local storage language is not supported then WARN log.
 * @returns { String } Retunn lang from localStorage if available and valid lang otherwise return default lang is return.
 */
export const getLanguage = () => {
  let lang = localStorage.getItem('lang') || 'en';
  if (supportedLanguage.indexOf(lang) === -1) {
    console.warn('This language is not supported:' + lang);
    lang = 'en';
  }
  return lang;
};

/**
 * Change application's language
 * @param {String} lang
 */
export const changeI18NextLanguage = (lang) => {
  if (!lang) {
    lang = 'en';
  }
  i18next.changeLanguage(lang);
};

export const init = () => {
  i18next.use(Backend).init({
    fallbackLng: 'en',
    lng: getLanguage(),
    ns: ['app'],
    defaultNS: 'app',
    backend: {
      loadPath: 'src/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading:  true
    },
    interpolation: { escapeValue: false },
  });
}

export default i18next;