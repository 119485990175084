export const UPDATE_DRAWER_OPENED = 'APP_UPDATE_DRAWER_OPENED';

/**
 * Opens or closes drawer.
 * @param {Boolean} opened `true` when drawer is opened.
 */
export const updateDrawerOpened = (opened) => {
  return {
    type: UPDATE_DRAWER_OPENED,
    opened
  }
}
