/**
 * Provides way to set title, customize header styles & manages browser history on open/close.
 *
 * - Usage pattern
 *    - This dialog can be used by extention only.
 *    - To customize header, content & footer
 *    - Override `_headerTemplate`, `_contentTemplate` & `_footerTemplate` for customization.
 */

 import { DwCompositeDialog } from '@dreamworld/dw-dialog/dw-composite-dialog.js';
 import { html, css } from  '@dreamworld/pwa-helpers/lit';
 import { connect } from '@dreamworld/pwa-helpers/connect-mixin.js';
 import { store } from '../../redux/store.js';
 import i18next from '../../localize/i18next.js';
 import localize from '../../localize/localize.js';
 import * as app from '../../redux/app';
 import { DialogHistoryManager } from './dialog-history-manager.js';
 import '../common/kerika-icon-button';
 import typographyLiterals from '../../theme/typography-literals';
 import { typography } from '../../theme/typography.js';
 import { sharedStyles } from '../../theme/shared-styles.js';
 import { theme } from '../../theme/theme';
 export class KerikaCompositeDialog extends connect(store)(localize(i18next)(DialogHistoryManager(DwCompositeDialog))) {

   constructor() {
     super();
     this.headerStyle = 'CLOSE';
     this.doNotDelayRendering = true;
     this.largeCloseIcon = false;
     this.__onMdcDialogScrimClick = this.__onMdcDialogScrimClick.bind(this);
     this.____onKeyDown = this.____onKeyDown.bind(this);
   }

   static get styles() {
     return [
       super.styles,
       typography,
       sharedStyles,
       theme,
       css`
         :host([type="modal"]) {
           /* Use app-header has 99 z-index */
           z-index: 100;
         }

         :host([type="fit"]),
          :host([type="fit"]) .mdc-dialog__container,
          :host([type="fit"]) .mdc-dialog__content {
            display: unset;
          }

         :host([type="modal"][placement="bottom"]) .mdc-dialog .mdc-dialog__surface {
           --dw-dialog-min-width: 320px;
         }

         :host([type="modal"][placement="center"]) .mdc-dialog .mdc-dialog__surface {
           --dw-dialog-min-height: 300px;
         }

         :host([type="fit"]) #dialog-content {
           color: var(--mdc-theme-text-primary);
           overflow: unset;
           padding-bottom: 0;
         }

         :host([type="modal"]) #dialogContainer{
           background-color: transparent;
           z-index: 100;
         }

         :host([type="modal"]) .mdc-dialog__surface {
           background-color: var(--mdc-theme-surface);
         }

         :host([placement="bottom"][type="modal"]) .mdc-dialog .mdc-dialog__surface{
           box-shadow: none;
           padding-top: 0 !important;
           padding-bottom: 0 !important;
           --dw-dialog-min-width: 320px;
         }

         #dialog-header {
           padding: 0px;
           border-color: var(--divider-color);
         }

         .header {
           padding: 8px 8px 8px 16px;
           box-sizing: border-box;
           display: flex;
           align-items: center;
         }

         .header.back-header {
           padding: 4px 8px;
           background-color: var(--app-header-background-color);
         }

         .back-icon kerika-icon-button {
           margin-left: 0 !important;
         }

         /* Because we has use custom */
         .mdc-dialog__title::before{
           display: none !important;
         }

         .close-header .title {
           text-transform: uppercase;
           color: var(--mdc-theme-secondary);
           font-weight: 400;
           font-size: 16px;
           line-height: 24px;
         }

         :host([type="fit"]) .back-header,
         :host([type="modal"]) .back-header,
         :host([type="popover"]) .back-header {
          color: var(--mdc-theme-secondary);
           cursor: default;
           outline: none;
           ${typographyLiterals.headline6};
           font-weight: 400;
           white-space: nowrap;
           overflow: hidden;
           text-overflow: ellipsis;
         }

         .back-header kerika-icon-button[icon="arrow_back"] {
           height: 48px;
           width: 48px;
           margin-right: 4px;
           margin-left: 4px;
         }

         :host([type="modal"]) #dialog-footer{
           display: flex;
           flex-wrap: wrap;
           justify-content: flex-end;
           padding: 0px;
           min-height: 56px;
           -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
           box-sizing: border-box;
         }

         :host([type="modal"]) #dialog-footer kerika-button {
           ${typographyLiterals.body1};
           margin: 8px;
         }

         :host([type="modal"][placement="bottom"]) #dialog-footer {
           padding: 0px 12px;
         }

         :host([type="modal"][placement="bottom"]) #dialog-footer kerika-button {
           flex: 1;
           flex-basis: 0.000000001px;
         }


         :host([type="fit"]) #dialog-header {
           padding: 0px;
           border-color: var(--divider-color);
         }

         :host([type="fit"]) .header {
           padding: 8px 8px 8px 16px;
           box-sizing: border-box;
         }

         :host([type="fit"]) .header.back-header {
           padding: 4px 8px;
           background-color: var(--mdc-theme-surface);
           justify-content: flex-start;
         }

         .close-header {
           justify-content: space-between;
         }

         :host([type="fit"]) .back-icon kerika-icon-button {
           margin-left: 0 !important;
         }

         :host([type="fit"]) .close-header .title {
           text-transform: uppercase;
         }

         :host([type="fit"]) .close-header .title{
           color: var(--mdc-theme-secondary) !important;
         }

         :host([type="fit"]) #dialog-footer {
          position: sticky !important;
          bottom: 0 !important;
          transform: none;
          background-color: var(--mdc-theme-surface);
          box-shadow: none !important;
          --dw-fit-dialog-footer-height: auto !important;
         }

         :host([type="fit"]) #dialog-footer,
         :host([type="fit"]) #dialog-content #action-buttons{
           display: flex;
           flex-wrap: wrap;
           justify-content: flex-end;
           min-height: var(--bottom-toolbar-height);
           -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
           box-sizing: border-box;
           padding: 0px 12px;
         }

         :host([type="fit"]) #dialog-content #action-buttons {
           background: var(--mdc-theme-surface);
           padding: 0;
           margin-top: 16px;
         }

         :host([type="fit"]) .wrapper:not([is-ios])[single-input] {
           overflow: hidden;
         }

         :host([type="fit"]) .wrapper:not([is-ios])[single-input] #dialog-content {
           background-color: var(--mdc-theme-surface);
           box-sizing: border-box;
           min-height: calc(100vh - var(--bottom-toolbar-height));
           max-height: calc(100vh - var(--bottom-toolbar-height));
           padding-bottom: 16px;
           overflow: auto;
         }

         :host([type="fit"]) .wrapper:not([is-ios])[single-input] #dialog-content #action-buttons {
           position: fixed;
           left: 0;
           right: 0;
           bottom: 0;
           padding: 0px 16px;
           margin: 0;
           max-width: var(--card-detail-dialog-max-width);
           margin: 0px auto;
         }

         :host([type="fit"]) #dialog-footer kerika-button,
         :host([type="fit"]) #dialog-content #action-buttons kerika-button {
           flex: 1;
           flex-basis: 0.000000001px;
           ${typographyLiterals.body1};
           margin: 8px;
         }

         :host([type="fit"]) #dialog-content #action-buttons kerika-button:not([hidden]):nth-child(1) {
           margin-left: 0;
         }

         :host([type="fit"]) #dialog-content #action-buttons kerika-button:not([hidden]):nth-last-child(1) {
           margin-right: 0;
         }

         :host(:not([has-footer])) footer {
           display: none !important;
         }

         :host([type="popover"]) .header.close-header {
           padding: 8px 12px 8px 24px;
           border-bottom: 1px solid var(--divider-color);
           box-sizing: border-box;
           height: var(--popover-header-height, 56px);
         }

         :host([type="popover"][menu]) .header.close-header {
           padding: 4px 8px 4px 16px;
           height: var(--popover-menu-header-height, 48px);
         }

         :host([type="popover"]) .header.close-header .title {
           ${typographyLiterals.subtitle1};
           text-transform: uppercase;
         }

         :host([type="popover"][menu]) .header.close-header .title {
           ${typographyLiterals.body2};
           text-transform: uppercase;
         }

         :host([type="popover"]) #dialog-content {
           ${typographyLiterals.body2};
         }

         :host([type="popover"]) #dialog-footer {
           display: flex;
           justify-content: center;
           padding: 12px 24px;
           border-top: 1px solid var(--divider-color);
           box-sizing: border-box;
         }

         :host([type="popover"][menu]) #dialog-footer {
           display: flex;
           justify-content: flex-end;
           padding: 8px 16px;
           border-top: 1px solid var(--divider-color);
           box-sizing: border-box;
         }

         :host([placement="center"][type="modal"]) .mdc-dialog__surface{
           margin: 32px auto;
           max-height: 90% !important;
           min-width: var(--dw-dialog-min-width, 800px);
           max-width: var(--dw-dialog-max-width, 800px);
         }

         :host([placement="center"][type="modal"]) .mdc-dialog__title .header {
           padding: 28px 32px 28px 40px;
         }

         :host([close-icon-hide][placement="center"][type="modal"]) .mdc-dialog__title .header {
           padding-top: 40px;
           padding-bottom: 40px;
         }

         :host([placement="center"][type="modal"]) .header.close-header .title {
           ${typographyLiterals.headline5};
           text-transform: none;
         }

         :host([placement="center"][type="modal"]) .mdc-dialog #dialog-content {
           padding: 0 40px 40px 40px;
           ${typographyLiterals.body1};
         }

         :host([placement="center"][type="modal"]) #dialog-footer kerika-button {
           margin: 0 0 0 16px;
         }

         :host([placement="center"][type="modal"]) #dialog-footer {
           padding: 40px;
         }
       `
     ]
   }



   static get properties() {
     return {
       /**
        * Input property.
        * When it is set, Render header title with close/back button.
        */
       title: {
         type: String
       },

       /**
        * Input property.
        * Represent header style.
        * Possible value: BACK, CLOSE
        * Default: CLOSE
        */
       headerStyle: {
         type: String
       },

       /**
        * Input property. When it's true, updates style according menu.
        */
       menu: { type: Boolean, reflect: true },

       /**
        * Close icon is disabled or not.
        */
       closeIconDisabled: { type: Boolean },

       /**
        * Close icon is hide or not.
        */
       closeIconHide: { type: Boolean, reflect: true, attribute: 'close-icon-hide' },

       /**
        * Close icon large or not.
        */
       largeCloseIcon: { type: Boolean },

       _layout: {type: String, reflect: true , attribute: 'layout'}

     }
   }

   /**
    * Getter of noCancelOnOutsideClick property.
    */
    get noCancelOnOutsideClick() {
     return this._noCancelOnOutsideClick;
   }

   /**
    * Setter of noCancelOnOutsideClick property.
    */
   set noCancelOnOutsideClick(value) {
     let oldValue = this._noCancelOnOutsideClick;
     if (oldValue == value) {
       return;
     }
     this._noCancelOnOutsideClick = value;
     this.requestUpdate('noCancelOnOutsideClick', oldValue);
     this._upateModalDialogCloseActions();
   }

   /**
    * Getter of noCancelOnEscKey property.
    */
    get noCancelOnEscKey() {
     return this._noCancelOnEscKey;
   }

   /**
    * Setter of noCancelOnEscKey property.
    */
   set noCancelOnEscKey(value) {
     let oldValue = this._noCancelOnEscKey;
     if (oldValue == value) {
       return;
     }
     this._noCancelOnEscKey = value;
     this.requestUpdate('noCancelOnEscKey', oldValue);
     this._upateModalDialogCloseActions();
   }

   /**
    * If `title` is provided, render header with title & close button on right side.
    * On `close-button` click, close dialog.
    */
   get _headerTemplate() {
     if (!this.title) {
       return '';
     }

     if (this.headerStyle === 'CLOSE') {
       return html`
         <div class="header close-header">
           <div class="title">${this.title}</div>
           <kerika-icon-button ?hidden=${this.closeIconHide} .disabled=${this.closeIconDisabled} icon="close" @click="${this._onCloseClick}" .large=${(this.placement === "center" && this.type === 'modal') || this.largeCloseIcon}></kerika-icon-button>
         </div>
       `
     }

     if(this.headerStyle === 'BACK'){
       return html`
         <div class="header back-header">
         <div class="back-icon">
           <kerika-icon-button .buttonSize=${48} icon="arrow_back" @click="${this._onCloseClick}"></kerika-icon-button>
         </div>
           <div class="title paper-font-title">${this.title}</div>
         </div>
       `
     }
   }

   updated(changedProps) {
     super.updated && super.updated(changedProps);
     if(changedProps.has('noCancelOnOutsideClick') || changedProps.has('opened')) {
       const el = this.renderRoot.querySelector('.mdc-dialog__scrim');
       if(el) {
         if(this.noCancelOnOutsideClick || !this.opened) {
           el.removeEventListener('click', this.__onMdcDialogScrimClick);
         } else {
           el.removeEventListener('click', this.__onMdcDialogScrimClick);
           el.addEventListener('click', this.__onMdcDialogScrimClick);
         }
       }
     }

     if(changedProps.has('noCancelOnEscKey') || changedProps.has('opened')) {
       if(this.noCancelOnOutsideClick || !this.opened) {
         window.removeEventListener('keydown', this.____onKeyDown);
       } else {
         window.removeEventListener('keydown', this.____onKeyDown);
         window.addEventListener('keydown', this.____onKeyDown);
       }
     }

     // Note: As `type` property is constant property for `dw-composite-dialog` but in kerika it can be changed on layout change.
     // So on change of it, re-open 'modal' dialog & on close, reset `_mdcDialogInstance`.
     if(changedProps.has('type') && changedProps.get('type') && this.opened) {
      if(changedProps.get('type') !== 'modal' && this.type === 'modal'){
        this.open();
      } else {
        this._mdcDialogInstance = null;
      }
     }
   }

   __onMdcDialogScrimClick(e) {
     this.__lastClosingReason = 'OUTSIDE_CLICK';
   }

   ____onKeyDown(e) {
     const keycode = e.keycode || e.which;
     const key = e.key;
     if(keycode === 27 || key === 'Escape' || key === 'Esc') {
       this.__lastClosingReason = 'ESC';
     }
   }

   /**
   * Close dialog.
   * @protected
   */
  _onCloseClick() {
    this.__lastClosingReason = 'CLOSE_ICON_BUTTON';
    this.close();
  }

   _onOpenedChanged(opened) {
     super._onOpenedChanged(opened);
     this.dialogHistoryManage();
   }

   /**
    * Update `scrimClickAction` and `escapeKeyAction` based on  `noCancelOnOutsideClick` and `noCancelOnOutsideClick` property.
    */
   _upateModalDialogCloseActions() {
     if(this._mdcDialogInstance) {
       this._mdcDialogInstance.scrimClickAction = this.noCancelOnOutsideClick ? '' : 'close';
       this._mdcDialogInstance.escapeKeyAction = this.noCancelOnEscKey ? '' : 'close';
     }
   }

   _setFocusToElement() {
     if (this.type !== 'modal') {
       super._setFocusToElement && super._setFocusToElement();
       return;
     }
     let el = this.renderRoot.querySelector(this.autoFocusSelector);

     if (!el) {
       el = this.querySelector(this.autoFocusSelector);
     }

     setTimeout(() => {
       el && el.focus && el.focus();
     }, 0);
   }

    stateChanged(state){
      super.stateChanged(state);
      this._layout = app.selectors.layout(state);
    }
 }

 window.customElements.define('kerika-composite-dialog', KerikaCompositeDialog);
